<template>
  <div>
    <vue-notification-list position="top-right"></vue-notification-list>
    <div>
      <label class="mb-2 block text-white" for="email">{{ t( 'Please enter the code received by email' ) }}</label>
      <InputText name="code" v-model="input.code"></InputText>
    </div>
    <div>
      <LoadingButton class="mt-3" type="button" ref="verify_btn" :label="t( 'Verify code' )" v-on:click="verify()"/>
    </div>
    <div class="mt-4 grid grid-cols-2 justify-items-stretch text-white">
      <div class="justify-self-start">
        <SelectLanguage />
      </div>
      <div class="justify-self-end">
        <a class="text-sm text-white hover:text-blue-400" href="#" v-on:click="click_back()">{{ t( 'Back' ) }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import ENUM_DISPLAY_COMPONENT from '../../enum/display.enum';
import AXIOS from '../../features/axios';
import utils from '../../features/utils';
import API_AUTH_CONST from '../../constants/api.constants';

export default {
  name: 'sign-in-verify-code-component',
  components: {
  },
  props: {
    user_id: { String, required: true }
  },
  setup( props, { emit } ) {
    const { t } = useI18n();
    const input = ref( { code: '' } );
    const verify_btn = ref( null );

    const click_back = () => {
      emit( 'custom-event-name', { display: ENUM_DISPLAY_COMPONENT.GET_CODE } );
    };

    const verify = () => {
      if ( input.value.code != '' && input.value.code.length >= API_AUTH_CONST.CODE_MIN_LENGTH && input.value.code.length <= API_AUTH_CONST.CODE_MIN_LENGTH ) {
        AXIOS.post( 'api/auth/password/verify', { id: props.user_id, code: input.value.code } )
          .then( response => {
            if ( response.status == '204' ) {
              emit( 'custom-event-name', { id: props.user_id, code: input.value.code, display: ENUM_DISPLAY_COMPONENT.CHANGE_PWD } );
              verify_btn.value.stop_loading();
            } else {
              utils.show_warning( response.data.msg );
              verify_btn.value.stop_loading();
            }
          } )
          .catch( e => {
            utils.show_catch( e );
            verify_btn.value.stop_loading();
          } );
      } else {
        utils.show_warning( t( 'Please enter a valid code' ) );
        verify_btn.value.stop_loading();
      }
    };

    return { t, input, verify_btn, verify, click_back };
  }
};
</script>

<style scoped lang="scss">
</style>
