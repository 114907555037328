<template>
  <div class="flex w-full justify-center">
    <div class="m-4 w-1/2 min-w-min max-w-4xl text-black">
      <Breadcrumb :breadcrumbs="breadcrumbs" class="mb-4"/>
      <div class="flex pb-4">
        <div class="grow">
          <span v-show="!current_id" class="text-2xl font-bold">{{ t( 'Create contact' ) }}</span>
          <span v-show="current_id" class="text-2xl font-bold">{{ t( 'Update contact' ) }}</span>
        </div>
        <div class="flex gap-2">
          <LoadingButton v-if="current_id && is_deleteable && is_delete_auth()" :label="t( 'Delete' )" ref="delete_btn" @click="click_to_delete()" colors="bg-red-600 text-white hover:bg-red-700"></LoadingButton>
          <LoadingButton v-if="is_create_or_update_auth()" :label="t( 'Save' )" ref="save_btn" @click="click_to_save()"></LoadingButton>
        </div>
      </div>
      <!-- Firstname -->
      <div class="mb-4 rounded-md shadow-md">
        <label class="mb-2 block bg-gray-100 p-2 font-bold" for="firstname">{{ t( 'Firstname' ) }}</label>
        <InputTextRequired ref="input_firstname" name="firstname" v-model="input.firstname" :show_required="input_firstname_required" @change="input_firstname_required = false" :key="key_rerender"></InputTextRequired>
      </div>
      <!-- Lastname -->
      <div class="mb-4 rounded-md shadow-md">
        <label class="mb-2 block bg-gray-100 p-2 font-bold" for="lastname">{{ t( 'Lastname' ) }}</label>
        <InputTextRequired ref="input_lastname" name="lastname" v-model="input.lastname" :show_required="input_lastname_required" @change="input_lastname_required = false" :key="key_rerender"></InputTextRequired>
      </div>
      <!-- Email -->
      <div class="mb-4 rounded-md shadow-md">
        <label class="mb-2 block bg-gray-100 p-2 font-bold" for="email">{{ t( 'Email' ) }}</label>
        <InputTextRequired ref="input_email" name="email" v-model="input.email" :show_required="input_email_required" @change="input_email_required = false" :key="key_rerender"
          :tooltip="email_tooltip" ></InputTextRequired>
      </div>
      <!-- Phone -->
      <div class="mb-4 rounded-md shadow-md">
        <label class="mb-2 block bg-gray-100 p-2 font-bold" for="phone">{{ t( 'Phone' ) }}</label>
        <InputText name="phone" v-model="input.phone" :key="key_rerender"></InputText>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useModal } from 'vue-final-modal';
import { useRoute } from 'vue-router';
import * as emailValidator from 'email-validator';
import router from '@/router';
import store from '@/store';
import axios from '@/features/axios';
import utils from '@/features/utils';
import ConfirmModal from '@/components/modal/ConfirmModal.vue';
import API_RIGHTS from '@/constants/rights.constants';

export default {
  name: 'contact-item-view',
  components: {
  },
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const current_id = utils.get_params_id( route.params.id );
    const breadcrumbs = ref ( [
      { label: t( 'Administration' ) },
      { label: t( 'Contacts' ), route: '/contacts' }
    ] );
    const input = ref( { firstname: '', lastname: '', email: '', phone: '' } );
    const save_btn = ref( null );
    const delete_btn = ref( null );
    const input_firstname = ref( null );
    const input_firstname_required = ref( null );
    const input_lastname = ref( null );
    const input_lastname_required = ref( null );
    const input_email = ref( null );
    const input_email_required = ref( null );
    const email_tooltip = ref( '' );
    const key_rerender = ref ( 0 );
    const is_deleteable = ref( false ) ;

    const force_init_rerender = () => {
      key_rerender.value += 1;
    };

    const initialize = () => {
      if ( current_id ) {
        axios.get( 'api/contact/' + current_id, { headers: { 'auth-token': store.getters.getToken } } )
          .then( async response => {
            if ( response.status == 200 ) {
              input.value.firstname = response.data.firstname;
              input.value.lastname = response.data.lastname;
              input.value.email = response.data.email;
              input.value.phone = response.data.phone;
              is_deleteable.value = response.data.is_deleteable;
              force_init_rerender();
            } else {
              utils.show_alert_from_resp( t, response );
              router.push( '/contacts' );
            }
          } )
          .catch( e => {
            analyse_catch( e );
          } );
      }
    };

    const click_to_save = () => {
      if ( typeof ( input.value.firstname ) != 'string' || input.value.firstname.trim() == '' ) {
        input_firstname_required.value = true;
        input_firstname.value.focus();
        save_btn.value.stop_loading();
        return;
      }
      if ( typeof ( input.value.lastname ) != 'string' || input.value.lastname.trim() == '' ) {
        input_lastname_required.value = true;
        input_lastname.value.focus();
        save_btn.value.stop_loading();
        return;
      }
      if ( typeof ( input.value.email ) != 'string' || input.value.email.trim() == '' ) {
        input_email_required.value = true;
        input_email.value.focus();
        save_btn.value.stop_loading();
        return;
      }
      if ( !emailValidator.validate( input.value.email ) ) {
        email_tooltip.value = t( 'Please enter a valid email address' );
        input_email_required.value = true;
        input_email.value.focus();
        save_btn.value.stop_loading();
        return;
      }

      input_firstname_required.value = false;
      input_lastname_required.value = false;
      input_email_required.value = false;

      if ( current_id ) {
        update_contact();
      } else {
        create_new_contact();
      }
    };

    const create_new_contact = () => {
      let data = {
        firstname: input.value.firstname,
        lastname: input.value.lastname,
        email: input.value.email,
        phone: input.value.phone ? ( input.value.phone.trim() != '' ? input.value.phone : undefined ) : undefined
      };

      axios.post( 'api/contact/', data, { headers: { 'auth-token': store.getters.getToken } } )
        .then( response => {
          if ( response.status == 200 ) {
            router.push( '/contacts' );
          } else {
            utils.show_alert_from_resp( t, response );
          }
          save_btn.value.stop_loading();
        } )
        .catch( e => {
          save_btn.value.stop_loading();
          analyse_catch( e );
        } );
    };

    const update_contact = () => {
      let data = {
        firstname: input.value.firstname,
        lastname: input.value.lastname,
        email: input.value.email,
        phone: input.value.phone ? ( input.value.phone.trim() != '' ? input.value.phone : undefined ) : undefined
      };

      axios.put( 'api/contact/' + current_id, data, { headers: { 'auth-token': store.getters.getToken } } )
        .then( response => {
          if ( response.status == 201 ) {
            router.push( '/contacts' );
          } else {
            utils.show_alert_from_resp( t, response );
          }
          save_btn.value.stop_loading();
        } )
        .catch( e => {
          save_btn.value.stop_loading();
          analyse_catch( e );
        } );
    };

    const click_to_delete = () => {
      confirm_delete_modal.open();
    };

    const confirm_delete_modal = useModal( {
      component: ConfirmModal,
      attrs: {
        title: t( 'Delete contact' ),
        message: t( 'Confirm delete contact' ),
        onConfirm() {
          delete_contact();
          confirm_delete_modal.close();
        },
        onCancel() {
          confirm_delete_modal.close();
          delete_btn.value.stop_loading();
        }
      }
    } );

    const delete_contact = () => {
      axios.delete( 'api/contact/' + current_id, { headers: { 'auth-token': store.getters.getToken } } )
        .then( response => {
          if ( response.status == 201 ) {
            router.push( '/contacts' );
          } else {
            utils.show_alert_from_resp( t, response );
          }
          delete_btn.value.stop_loading();
        } )
        .catch( e => {
          delete_btn.value.stop_loading();
          analyse_catch( e );
        } );
    };

    const is_create_auth = () => {
      return utils.is_auth( [ API_RIGHTS.CREATE_CONTACTS ] );
    };

    const is_update_auth = () => {
      return utils.is_auth( [ API_RIGHTS.UPDATE_CONTACTS ] );
    };

    const is_delete_auth = () => {
      return utils.is_auth( [ API_RIGHTS.DELETE_CONTACTS ] );
    };

    const is_create_or_update_auth = () => {
      return current_id && is_update_auth() || !current_id && is_create_auth();
    };

    const analyse_catch = e => {
      utils.analyse_catch( e, router, '/contacts' );
    };

    initialize();

    return { t, current_id, key_rerender, is_deleteable,
      save_btn, delete_btn, input_firstname, input_lastname, input_email,
      input_firstname_required, input_lastname_required, input_email_required,
      breadcrumbs, input, email_tooltip,
      is_create_auth, is_update_auth, is_delete_auth, is_create_or_update_auth,
      click_to_save, click_to_delete };
  }
};
</script>>