import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import Cookies from 'js-cookie';

export default createStore( {
  state: {
    i18n: {
      locale: 'fr'
    },
    auth: {
      connect: false,
      token: ''
    }
  },
  getters: {
    getLocale( state ) {
      return state.i18n.locale;
    },
    isAuthenticated: state => {
      return state.auth.connect;
    },
    get_connected_user: state => {
      return state.auth.user;
    },
    getToken( state ) {
      return state.auth.token;
    }
  },
  mutations: {
    SET_LOCALE( state, locale ) {
      state.i18n.locale = locale;
    },
    SET_CONNECTED( state, connect ) {
      state.auth.connect = Boolean( connect );
    },
    SET_CONNECTED_USER( state, connect_user ) {
      state.auth.user = connect_user;
    },
    SET_TOKEN( state, token ) {
      state.auth.token = token;
    }
  },
  actions: {
    setLocale( { commit }, { locale } ) {
      commit( 'SET_LOCALE', locale );
    },
    setConnected( { commit }, { connect, connect_user, token } ) {
      commit( 'SET_CONNECTED', connect );
      commit( 'SET_CONNECTED_USER', connect_user );
      commit( 'SET_TOKEN', token );
    },
    updateToken( { commit }, token ) {
      commit( 'SET_TOKEN', token );
    }
  },
  modules: {
  },
  plugins: [
    createPersistedState( {
      storage: {
        getItem: key => Cookies.get( key ),
        setItem: ( key, value ) =>
          Cookies.set( key, value, { expires: ( process.env.VUE_APP_SESSION_EXPIRES_IN / 1440 ), secure: true } ),
        removeItem: key => Cookies.remove( key ),
      },
    } )
  ]
} );
