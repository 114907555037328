<template>
  <div class="px-2 pb-2">
    <InputStd ref="input_std" type="number" :min="min" :max="max" name="value" :readonly="readonly" v-model="val" @input="$emit('update:modelValue', $event.target.value)"></InputStd>
  </div>
</template>

<script>
import { ref } from 'vue';
import InputStd from './InputStd.vue';

export default {
  name: 'input-text-component',
  components: {
    InputStd
  },
  props: {
    modelValue: { String, required: true },
    readonly: { Boolean, required: false, default: false },
    min: { Number, require: true },
    max: { Number, require: true }
  },
  setup( props ) {
    const input_std = ref ( null );
    const val = ref( props.modelValue );

    const focus = () => {
      input_std.value.focus();
    };

    return { input_std, val, focus };
  }
};
</script>
