<template>
  <div class="flex w-full justify-center">
    <div class="m-4 w-1/2 min-w-min max-w-4xl text-black">
      <Breadcrumb :breadcrumbs="breadcrumbs" class="mb-4"/>
      <div class="flex pb-4">
        <div class="grow">
          <span v-show="!current_id" class="text-2xl font-bold">{{ t( 'Create customer' ) }}</span>
          <span v-show="current_id" class="text-2xl font-bold">{{ t( 'Update customer' ) }}</span>
        </div>
        <div class="flex gap-2">
          <LoadingButton v-if="current_id && is_deleteable && is_delete_auth()" :label="t( 'Delete' )" ref="delete_btn" @click="click_to_delete()" colors="bg-red-600 text-white hover:bg-red-700"></LoadingButton>
          <LoadingButton v-if="is_create_or_update_auth()" :label="t( 'Save' )" ref="save_btn" @click="click_to_save()"></LoadingButton>
        </div>
      </div>
      <!-- Name -->
      <div class="mb-4 rounded-md shadow-md">
        <label class="mb-2 block bg-gray-100 p-2 font-bold" for="name">{{ t( 'Name' ) }}</label>
        <InputTextRequired ref="input_name" name="name" v-model="input.name" :show_required="input_name_required" @change="input_name_required = false" :key="key_rerender"></InputTextRequired>
      </div>
      <!-- Sites -->
      <div class="mb-4 rounded-md shadow-md">
        <div class="flex w-full items-center bg-gray-100">
          <label class="block grow p-2 font-bold" for="name">{{ t( 'Sites' ) }}
            <span class="inline-flex items-center rounded-md bg-green-800 px-1.5 py-0.5 text-xs font-medium text-white ring-1 ring-inset ring-indigo-700/10">{{ site_items.length }}</span>
          </label>
          <div v-if="is_create_or_update_auth() && current_id">
            <IconEditButton :tooltip="t( 'Add new site' )" @click="add_site">
              <PlusBoxIcon></PlusBoxIcon>
            </IconEditButton>
          </div>
        </div>
        <EasyDataTable
        buttons-pagination alternating
        table-class-name="customize-table"
        :empty-message="current_id ? t( 'No Available Data' ) : t( 'Create customer before' )"
        :headers="site_headers"
        :items="site_items"
        >
          <template #item-operation="item">
            <div class="operation-wrapper">
              <PencilOutlineIcon v-if="is_create_or_update_auth()" @click="update_site(item)"></PencilOutlineIcon>
            </div>
          </template>
        </EasyDataTable>
      </div>
      <!-- Contacts -->
      <div class="mb-4 rounded-md shadow-md">
        <div class="flex w-full items-center bg-gray-100">
          <label class="block grow p-2 font-bold" for="name">{{ t( 'Contacts' ) }}
            <span class="inline-flex items-center rounded-md bg-green-800 px-1.5 py-0.5 text-xs font-medium text-white ring-1 ring-inset ring-indigo-700/10">{{ contact_items.length }}</span>
          </label>
          <div v-if="is_create_or_update_auth()">
            <IconEditButton :tooltip="t( 'Add new contact' )" @click="add_contacts">
              <PlusBoxIcon></PlusBoxIcon>
            </IconEditButton>
          </div>
        </div>
        <EasyDataTable
          buttons-pagination alternating
          table-class-name="customize-table"
          :empty-message="t( 'No Available Data' )"
          :headers="contact_headers"
          :items="contact_items"
        >
          <template #item-operation="item">
            <div class="operation-wrapper">
              <DeleteOutlineIcon v-if="is_create_or_update_auth()" @click="remove_contact(item)"></DeleteOutlineIcon>
            </div>
          </template>
        </EasyDataTable>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useModal } from 'vue-final-modal';
import { useRoute } from 'vue-router';
import moment from 'moment';
import router from '@/router';
import store from '@/store';
import axios from '@/features/axios';
import utils from '@/features/utils';
import IconEditButton from '@/components/buttons/IconEditButton.vue';
import AddContactsModal from '@/components/modal/AddContactsModal.vue';
import ConfirmModal from '@/components/modal/ConfirmModal.vue';
import API_RIGHTS from '@/constants/rights.constants';

import DeleteOutlineIcon from 'vue-material-design-icons/DeleteOutline.vue';
import PlusBoxIcon from 'vue-material-design-icons/PlusBox.vue';
import PencilOutlineIcon from 'vue-material-design-icons/PencilOutline.vue';

export default {
  name: 'customer-item-view',
  components: {
    IconEditButton,
    DeleteOutlineIcon,
    PlusBoxIcon,
    PencilOutlineIcon
  },
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const current_id = utils.get_params_id( route.params.id );
    const breadcrumbs = ref ( [
      { label: t( 'Administration' ) },
      { label: t( 'Customers' ), route: '/customers' }
    ] );
    const input = ref( { name: '' } );
    const save_btn = ref( null );
    const delete_btn = ref( null );
    const input_name = ref( null );
    const input_name_required = ref( null );
    const site_headers = ref( [
      { text: t( 'Name' ), value: 'name', sortable: true },
      { text: t( 'Number of contacts' ), value: 'number_of_contacts', sortable: true, width: 100 },
      { text: t( 'Last Activity' ), value: 'last_activity', sortable: true, width: 200 },
      { text: '', value: 'operation', sortable: false, width: 20 }
    ] );
    const contact_headers = ref( [
      { text: t( 'Lastname' ), value: 'lastname', sortable: true },
      { text: t( 'Firstname' ), value: 'firstname', sortable: true },
      { text: t( 'Email' ), value: 'email', sortable: true },
      { text: '', value: 'operation', sortable: false, width: 20 }
    ] );
    const site_items = ref( [] );
    const contact_items = ref( [] );
    const key_rerender = ref ( 0 );
    const is_deleteable = ref( false ) ;

    const force_init_rerender = () => {
      key_rerender.value += 1;
    };

    const initialize = () => {
      if ( current_id ) {
        axios.get( 'api/customer/' + current_id, { headers: { 'auth-token': store.getters.getToken } } )
          .then( async response => {
            if ( response.status == 200 ) {
              input.value.name = response.data.name;
              is_deleteable.value = response.data.is_deleteable;
              let promises = [];
              for await ( const id_sites of response.data.sites ) {
                let promise = axios.get( 'api/customer_site/' + id_sites, { headers: { 'auth-token': store.getters.getToken } } )
                  .then( async response => {
                    if ( response.status == 200 ) {
                      response.data.register_date = moment( response.data.register_date ).format( 'LLL' );
                      response.data.last_activity = moment( response.data.last_activity ).format( 'LLL' );
                      response.data.number_of_contacts = response.data.contacts.length ?? 0;
                      site_items.value.push( { value: id_sites, label: response.data.name, ...response.data } );
                    } else {
                      utils.show_alert_from_resp( t, response );
                      router.push( '/customers' );
                    }
                  } )
                  .catch( e => {
                    analyse_catch( e );
                  } );
                if ( promise ) {
                  promises.push( promise );
                }
              }
              for await ( const id_contact of response.data.contacts ) {
                let promise = axios.get( 'api/contact/' + id_contact, { headers: { 'auth-token': store.getters.getToken } } )
                  .then( async response => {
                    if ( response.status == 200 ) {
                      contact_items.value.push( { value: id_contact, ...response.data } );
                    } else {
                      utils.show_alert_from_resp( t, response );
                      router.push( '/customers' );
                    }
                  } )
                  .catch( e => {
                    analyse_catch( e );
                  } );
                if ( promise ) {
                  promises.push( promise );
                }
              }
              Promise.all( promises ).then( () => {
                force_init_rerender();
              } );
            } else {
              utils.show_alert_from_resp( t, response );
              router.push( '/customers' );
            }
          } )
          .catch( e => {
            analyse_catch( e );
          } );
      }
    };

    const click_to_save = () => {
      if ( typeof ( input.value.name ) != 'string' || input.value.name.trim() == '' ) {
        input_name_required.value = true;
        input_name.value.focus();
        save_btn.value.stop_loading();
        return;
      }

      input_name_required.value = false;

      let contacts = contact_items.value.map( item => { return item.value; } );

      if ( current_id ) {
        update_customer( input.value.name, contacts );
      } else {
        create_new_customer( input.value.name, contacts );
      }
    };

    const create_new_customer = ( name, contacts ) => {
      let data = {
        name: name,
        contacts: contacts.length > 0 ? contacts : undefined
      };

      axios.post( 'api/customer/', data, { headers: { 'auth-token': store.getters.getToken } } )
        .then( response => {
          if ( response.status == 200 ) {
            router.push( '/customers' );
          } else {
            utils.show_alert_from_resp( t, response );
          }
          save_btn.value.stop_loading();
        } )
        .catch( e => {
          save_btn.value.stop_loading();
          analyse_catch( e );
        } );
    };

    const update_customer = ( name, contacts ) => {
      let data = {
        name: name,
        contacts: { set: contacts }
      };

      axios.put( 'api/customer/' + current_id, data, { headers: { 'auth-token': store.getters.getToken } } )
        .then( response => {
          if ( response.status == 201 ) {
            router.push( '/customers' );
          } else {
            utils.show_alert_from_resp( t, response );
          }
          save_btn.value.stop_loading();
        } )
        .catch( e => {
          save_btn.value.stop_loading();
          analyse_catch( e );
        } );
    };

    const click_to_delete = () => {
      confirm_delete_modal.open();
    };

    const confirm_delete_modal = useModal( {
      component: ConfirmModal,
      attrs: {
        title: t( 'Delete customer' ),
        message: t( 'Confirm delete customer' ),
        onConfirm() {
          delete_customer();
          confirm_delete_modal.close();
        },
        onCancel() {
          confirm_delete_modal.close();
          delete_btn.value.stop_loading();
        }
      }
    } );

    const delete_customer = () => {
      axios.delete( 'api/customer/' + current_id, { headers: { 'auth-token': store.getters.getToken } } )
        .then( response => {
          if ( response.status == 201 ) {
            router.push( '/customers' );
          } else {
            utils.show_alert_from_resp( t, response );
          }
          delete_btn.value.stop_loading();
        } )
        .catch( e => {
          delete_btn.value.stop_loading();
          analyse_catch( e );
        } );
    };

    const add_contacts = () => {
      add_contacts_modal.open();
    };

    const remove_contact = item => {
      let idx = contact_items.value.findIndex( it => it.value == item.value );
      contact_items.value.splice( idx, 1 );
    };

    const add_contacts_modal = useModal( {
      component: AddContactsModal,
      attrs: {
        contacts: contact_items,
        onCancel() {
          add_contacts_modal.close();
        },
        onSave( selection ) {
          contact_items.value = contact_items.value.concat( selection );
          add_contacts_modal.close();
        }
      }
    } );

    const add_site = () => {
      router.push( '/customer-sites/' + current_id + '/create-new' );
    };

    const update_site = item => {
      router.push( '/customer-sites/' + current_id + '/' + item.id );
    };

    const is_create_auth = () => {
      return utils.is_auth( [ API_RIGHTS.CREATE_CUSTOMERS, API_RIGHTS.GET_CUSTOMER_SITES, API_RIGHTS.GET_CONTACTS ] );
    };

    const is_update_auth = () => {
      return utils.is_auth( [ API_RIGHTS.UPDATE_CUSTOMERS, API_RIGHTS.GET_CUSTOMER_SITES, API_RIGHTS.GET_CONTACTS ] );
    };

    const is_delete_auth = () => {
      return utils.is_auth( [ API_RIGHTS.DELETE_CUSTOMERS ] );
    };

    const is_create_or_update_auth = () => {
      return current_id && is_update_auth() || !current_id && is_create_auth();
    };

    const analyse_catch = e => {
      utils.analyse_catch( e, router, '/customers' );
    };

    initialize();

    return { t, current_id, key_rerender, is_deleteable,
      save_btn, delete_btn, input_name, input_name_required,
      breadcrumbs, input, site_headers, contact_headers, site_items, contact_items,
      is_create_auth, is_update_auth, is_delete_auth, is_create_or_update_auth,
      click_to_save, click_to_delete, add_contacts, remove_contact, add_site, update_site };
  }
};
</script>>