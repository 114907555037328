<template>
  TODO :DASHBOARD VIEW
</template>

<script>
export default {
  name: 'dashboard-view',
  components: {
  },
  setup() {
    return {};
  }
};
</script>

<style scoped lang="scss">
</style>