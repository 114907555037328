<template>
  <vue-notification-list position="top-right"></vue-notification-list>
  <div class="flex h-screen flex-col">
    <div class="grow pb-9">
      <router-view />
    </div>
    <div class="fixed bottom-0 w-full">
      <Copyright />
    </div>
  </div>
  <ModalsContainer />
</template>

<script>
import Copyright from '@/components/Copyright.vue';
import { useI18n } from 'vue-i18n';
import { ModalsContainer } from 'vue-final-modal';
import store from '@/store';

export default {
  name: 'App',
  components: {
    Copyright,
    ModalsContainer
  },
  setup() {
    const { t, locale } = useI18n();

    locale.value = store.getters.getLocale;

    return { t };
  }
};
</script>
