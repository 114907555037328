<template>
  <div>
    <form>
      <div>
        <label class="mb-2 block text-white" for="email">{{ t( 'Password' ) }}</label>
        <InputPassword name="password" v-model="input.password"></InputPassword>
      </div>
      <div>
        <label class="mb-2 block text-white" for="password">{{ t( 'Confirm Password' ) }}</label>
        <InputPassword name="confirm-password" v-model="input.password_confirm"></InputPassword>
      </div>
      <div>
        <LoadingButton class="mt-3" type="button" ref="change_btn" :label="t( 'Change Password' )" v-on:click="change_password()"/>
      </div>
    </form>
    <div class="mt-4 grid grid-cols-2 justify-items-stretch text-white">
      <div class="justify-self-start">
        <SelectLanguage/>
      </div>
      <div class="justify-self-end">
        <a class="text-sm text-white hover:text-blue-400" href="#" v-on:click="signin()">{{ t( 'Sign-in' ) }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, inject } from 'vue';
import { useI18n } from 'vue-i18n';
import ENUM_DISPLAY_COMPONENT from '@/enum/display.enum';
import axios from '../../features/axios';
import utils from '@/features/utils';
import passwordValidator from '@/features/passwordValidator';

export default {
  name: 'change-password-component',
  components: {
  },
  props: {
    user_id: { String, required: true },
    code: { String, required: true }
  },
  setup( props, { emit } ) {
    const { t } = useI18n();
    const input = ref( { password: '', password_confirm: '' } );
    const change_btn = ref( null );
    const cryptojs = inject( 'cryptojs' );

    const signin = () => {
      emit( 'custom-event-name', { display: ENUM_DISPLAY_COMPONENT.SIGNIN } );
    };

    const change_password = () => {
      let resultPassValidator = passwordValidator.validate( input.value.password );

      if ( input.value.password != '' ) {
        if ( input.value.password != input.value.password_confirm ) {
          utils.show_warning( t( 'Passwords do not match' ) );
          change_btn.value.stop_loading();
        } else if ( resultPassValidator.length > 0 ) {
          utils.show_warning( resultPassValidator[ 0 ].message );
          change_btn.value.stop_loading();
        } else {
          axios.post( 'api/auth/password/new', { id: props.user_id, code: props.code, password: cryptojs.HmacSHA1( input.value.password, process.env.VUE_APP_SECRET_PASSWORD ).toString() } )
            .then( response => {
              if ( response.status == '201' ) {
                utils.show_sucess( t( 'Your password are changed' ) );
                emit( 'custom-event-name', { display: ENUM_DISPLAY_COMPONENT.SIGNIN } );
                change_btn.value.stop_loading();
              } else if ( response.status == '202' ) {
                utils.show_sucess( t( 'Your password are NOT changed because' ).replace( '{0}', response.data.msg ) );
                emit( 'custom-event-name', { display: ENUM_DISPLAY_COMPONENT.SIGNIN } );
                change_btn.value.stop_loading();
              } else {
                utils.show_alert( t( 'Your password are NOT changed' ) );
                change_btn.value.stop_loading();
              }
            } )
            .catch( e => {
              utils.show_catch( e );
              change_btn.value.stop_loading();
            } );
        }
      } else {
        utils.show_alert( t( 'Please enter a valid password' ) );
        change_btn.value.stop_loading();
      }
    };

    return { t, input, change_btn, signin, change_password };
  }
};
</script>