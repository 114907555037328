<template>
  <div>
    <div>
      <label class="mb-2 block text-white" for="email">{{ t( 'Please enter your email address' ) }}</label>
      <InputText name="email" v-model="input.email"></InputText>
    </div>
    <div>
      <LoadingButton class="mt-3" type="button" ref="reset_btn" :label="t( 'Receive code' )" v-on:click="reset()"/>
    </div>
    <div class="mt-4 grid grid-cols-2 justify-items-stretch text-white">
      <div class="justify-self-start">
        <SelectLanguage />
      </div>
      <div class="justify-self-end">
        <a class="text-sm text-white hover:text-blue-400" href="#" v-on:click="click_back()">{{ t( 'Back' ) }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import ENUM_DISPLAY_COMPONENT from '../../enum/display.enum';
import * as EmailValidator from 'email-validator';
import AXIOS from '../../features/axios.js';
import utils from '../../features/utils.js';

export default {
  name: 'sign-in-get-code-component',
  components: {
  },
  setup( props, { emit } ) {
    const { t } = useI18n();
    const input = ref( { email: '' } );
    const reset_btn = ref( null );

    const click_back = () => {
      emit( 'custom-event-name', { display: ENUM_DISPLAY_COMPONENT.SIGNIN } );
    };

    const reset = () => {
      if ( EmailValidator.validate( input.value.email ) ) {
        AXIOS.post( 'api/auth/password/code', { email: input.value.email } )
          .then( response => {
            if ( response.status == '200' ) {
              emit( 'custom-event-name', { id: response.data.id,  display: ENUM_DISPLAY_COMPONENT.VERIFY_CODE } );
              reset_btn.value.stop_loading();
            } else {
              utils.show_warning( response.data.msg );
              reset_btn.value.stop_loading();
            }
          } )
          .catch( e => {
            utils.show_catch( e );
            reset_btn.value.stop_loading();
          } );
      } else {
        utils.show_warning( t( 'Please enter a valid email address' ) );
        reset_btn.value.stop_loading();
      }
    };

    return { t, input, reset_btn, reset, click_back };
  }
};
</script>