<template>
  <div class="flex w-full justify-center">
    <div class="m-4 w-1/2 min-w-min max-w-4xl text-black">
      <Breadcrumb :breadcrumbs="breadcrumbs" class="mb-4"/>
      <div class="flex pb-4">
        <div class="grow">
          <span v-show="!current_id" class="text-2xl font-bold">{{ t( 'Create user' ) }}</span>
          <span v-show="current_id" class="text-2xl font-bold">{{ t( 'Update user' ) }}</span>
        </div>
        <div class="flex gap-2">
          <div v-if="is_deleteable">
            <LoadingButton v-if="current_id && !is_lock && is_lock_auth()" :label="t( 'Lock' )" ref="lock_btn" @click="click_to_lock()" colors="bg-red-600 text-white hover:bg-red-700"></LoadingButton>
            <LoadingButton v-if="current_id && is_lock && is_unlock_auth()" :label="t( 'Unlock' )" ref="unlock_btn" @click="click_to_unlock()" colors="bg-red-600 text-white hover:bg-red-700"></LoadingButton>
          </div>
          <LoadingButton v-if="is_create_or_update_auth()" :label="t( 'Save' )" ref="save_btn" @click="click_to_save()"></LoadingButton>
        </div>
      </div>
      <!-- Firstname -->
      <div class="mb-4 rounded-md shadow-md">
        <label class="mb-2 block bg-gray-100 p-2 font-bold" for="firstname">{{ t( 'Firstname' ) }}</label>
        <InputTextRequired ref="input_firstname" name="firstname" v-model="input.firstname" :show_required="input_firstname_required" @change="input_firstname_required = false" :key="key_rerender"></InputTextRequired>
      </div>
      <!-- Lastname -->
      <div class="mb-4 rounded-md shadow-md">
        <label class="mb-2 block bg-gray-100 p-2 font-bold" for="lastname">{{ t( 'Lastname' ) }}</label>
        <InputTextRequired ref="input_lastname" name="lastname" v-model="input.lastname" :show_required="input_lastname_required" @change="input_lastname_required = false" :key="key_rerender"></InputTextRequired>
      </div>
      <!-- Email -->
      <div class="mb-4 rounded-md shadow-md">
        <label class="mb-2 block bg-gray-100 p-2 font-bold" for="email">{{ t( 'Email' ) }}</label>
        <InputTextRequired ref="input_email" name="email" v-model="input.email" :show_required="input_email_required" @change="input_email_required = false" :key="key_rerender"
          :tooltip="email_tooltip" ></InputTextRequired>
      </div>
      <!-- Password -->
      <div class="mb-4 rounded-md shadow-md">
        <label class="mb-2 block bg-gray-100 p-2 font-bold" for="password">{{ t( 'Password' ) }}</label>
        <InputPasswordRequired ref="input_password" name="password" v-model="input.password" :show_required="input_password_required" @change="input_password_required = false"
          :tooltip="password_tooltip" :key="key_rerender"></InputPasswordRequired>
      </div>
      <!-- Confirm Password -->
      <div class="mb-4 rounded-md shadow-md">
        <label class="mb-2 block bg-gray-100 p-2 font-bold" for="password_confirm">{{ t( 'Confirm Password' ) }}</label>
        <InputPasswordRequired name="password_confirm" v-model="input.password_confirm"></InputPasswordRequired>
      </div>
      <!-- Language -->
      <div class="mb-4 rounded-md shadow-md">
        <label class="mb-2 block bg-gray-100 p-2 font-bold" for="language">{{ t( 'Language' ) }}</label>
        <Multiselect v-model="input.language" :options="languages" mode="single" :searchable="true" class="customize-multiselect h-8"
        :noResultsText="t( 'No results found' )" :noOptionsText="t( 'The list is empty' )" :canClear="false" valueProp="key" label="name">
          <template v-slot:singlelabel="{ value }">
            <div class="multiselect-single-label">
              <img  class="max-h-[1.75em] max-w-[1.75em]" :src="require(`../../public/flags/${value.key}.png`)" /><span class="px-2">{{ value.name }}</span>
            </div>
          </template>
          <template v-slot:option="{ option }">
            <img  class="max-h-[1.75em] max-w-[1.75em]" :src="require(`../../public/flags/${option.key}.png`)" /><span class="px-2">{{ option.name }}</span>
          </template>
        </Multiselect>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, inject } from 'vue';
import { useI18n } from 'vue-i18n';
import { useModal } from 'vue-final-modal';
import { useRoute } from 'vue-router';
import * as emailValidator from 'email-validator';
import router from '@/router';
import store from '@/store';
import axios from '@/features/axios';
import utils from '@/features/utils';
import passwordValidator from '@/features/passwordValidator';
import ConfirmModal from '@/components/modal/ConfirmModal.vue';
import API_RIGHTS from '@/constants/rights.constants';

export default {
  name: 'user-item-view',
  components: {
  },
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const cryptojs = inject( 'cryptojs' );
    const current_id = utils.get_params_id( route.params.id );
    const breadcrumbs = ref ( [
      { label: t( 'Administration' ) },
      { label: t( 'Users' ), route: '/users' }
    ] );
    const input = ref( { firstname: '', lastname: '', email: '', password: '', password_confirm: '', language: utils.get_default_language() } );
    const save_btn = ref( null );
    const lock_btn = ref( null );
    const unlock_btn = ref( null );
    const input_firstname = ref( null );
    const input_firstname_required = ref( null );
    const input_lastname = ref( null );
    const input_lastname_required = ref( null );
    const input_email = ref( null );
    const input_email_required = ref( null );
    const input_password = ref( null );
    const input_password_required = ref( null );
    const key_rerender = ref ( 0 );
    const password_tooltip = ref ( '' );
    const email_tooltip = ref( '' );
    const is_lock = ref( '' ) ;
    const is_deleteable = ref( false ) ;

    const languages = ref( utils.get_language_supported() );

    const force_init_rerender = () => {
      key_rerender.value += 1;
    };

    const initialize = () => {
      if ( current_id ) {
        axios.get( 'api/user/' + current_id, { headers: { 'auth-token': store.getters.getToken } } )
          .then( async response => {
            if ( response.status == 200 ) {
              input.value.firstname = response.data.firstname;
              input.value.lastname = response.data.lastname;
              input.value.email = response.data.email;
              input.value.language = response.data.language;
              is_deleteable.value = response.data.is_deleteable;
              is_lock.value = response.data.account_lock;
              force_init_rerender();
            } else {
              utils.show_alert_from_resp( t, response );
              router.push( '/users' );
            }
          } )
          .catch( e => {
            analyse_catch( e );
          } );
      }
    };

    const click_to_save = () => {
      if ( typeof ( input.value.firstname ) != 'string' || input.value.firstname.trim() == '' ) {
        input_firstname_required.value = true;
        input_firstname.value.focus();
        save_btn.value.stop_loading();
        return;
      }
      if ( typeof ( input.value.lastname ) != 'string' || input.value.lastname.trim() == '' ) {
        input_lastname_required.value = true;
        input_lastname.value.focus();
        save_btn.value.stop_loading();
        return;
      }
      if ( typeof ( input.value.email ) != 'string' || input.value.email.trim() == '' ) {
        input_email_required.value = true;
        input_email.value.focus();
        save_btn.value.stop_loading();
        return;
      }
      if ( !emailValidator.validate( input.value.email ) ) {
        email_tooltip.value = t( 'Please enter a valid email address' );
        input_email_required.value = true;
        input_email.value.focus();
        save_btn.value.stop_loading();
        return;
      }
      if ( !current_id || input.value.password.trim() != '' ) {
        if ( typeof ( input.value.password ) != 'string' || input.value.password.trim() == '' ) {
          password_tooltip.value = t( 'Please complete this field.' );
          input_password_required.value = true;
          input_password.value.focus();
          save_btn.value.stop_loading();
          return;
        }

        let validation = passwordValidator.validate( input.value.password );
        if ( validation.length > 0 ) {
          password_tooltip.value = validation[ 0 ].message;
          input_password_required.value = true;
          input_password.value.focus();
          save_btn.value.stop_loading();
          return;
        }

        if ( input.value.password != input.value.password_confirm ) {
          password_tooltip.value = t( 'Passwords do not match.' );
          input_password_required.value = true;
          input_password.value.focus();
          save_btn.value.stop_loading();
          return;
        }
      }

      input_firstname_required.value = false;
      input_lastname_required.value = false;
      input_email_required.value = false;
      input_password_required.value = false;

      if ( current_id ) {
        update_user();
      } else {
        create_new_user();
      }
    };

    const create_new_user = () => {
      let data = {
        firstname: input.value.firstname,
        lastname: input.value.lastname,
        email: input.value.email,
        password: cryptojs.HmacSHA1( input.value.password, process.env.VUE_APP_SECRET_PASSWORD ).toString(),
        language: input.value.language
      };

      axios.post( 'api/user/register', data, { headers: { 'auth-token': store.getters.getToken } } )
        .then( response => {
          if ( response.status == 200 ) {
            router.push( '/users' );
          } else {
            utils.show_alert_from_resp( t, response );
          }
          save_btn.value.stop_loading();
        } )
        .catch( e => {
          save_btn.value.stop_loading();
          analyse_catch( e );
        } );
    };

    const update_user = () => {
      let data = {
        firstname: input.value.firstname,
        lastname: input.value.lastname,
        language: input.value.language,
        password: input.value.password.trim() != '' ? cryptojs.HmacSHA1( input.value.password, process.env.VUE_APP_SECRET_PASSWORD ).toString() : undefined,
      };

      axios.put( 'api/user/account/' + current_id, data, { headers: { 'auth-token': store.getters.getToken } } )
        .then( response => {
          if ( response.status == 201 ) {
            router.push( '/users' );
          } else {
            utils.show_alert_from_resp( t, response );
          }
          save_btn.value.stop_loading();
        } )
        .catch( e => {
          console.log( e );
          save_btn.value.stop_loading();
          analyse_catch( e );
        } );
    };

    const click_to_lock = () => {
      confirm_lock_modal.open();
    };

    const confirm_lock_modal = useModal( {
      component: ConfirmModal,
      attrs: {
        title: t( 'Lock user' ),
        message: t( 'Confirm lock user' ),
        onConfirm() {
          confirm_lock_modal.close();
          lock_user();
        },
        onCancel() {
          confirm_lock_modal.close();
          lock_btn.value.stop_loading();
        }
      }
    } );

    const lock_user = () => {
      axios.post( 'api/user/lock/' + current_id, {}, { headers: { 'auth-token': store.getters.getToken } } )
        .then( response => {
          if ( response.status == 201 ) {
            is_lock.value = true;
          } else {
            utils.show_alert_from_resp( t, response );
          }
          lock_btn.value.stop_loading();
        } )
        .catch( e => {
          lock_btn.value.stop_loading();
          analyse_catch( e );
        } );
    };

    const click_to_unlock = () => {
      confirm_unlock_modal.open();
    };

    const confirm_unlock_modal = useModal( {
      component: ConfirmModal,
      attrs: {
        title: t( 'Unlock user' ),
        message: t( 'Confirm unlock user' ),
        onConfirm() {
          confirm_unlock_modal.close();
          unlock_user();
        },
        onCancel() {
          confirm_unlock_modal.close();
          unlock_btn.value.stop_loading();
        }
      }
    } );

    const unlock_user = () => {
      axios.post( 'api/user/unlock/' + current_id, {}, { headers: { 'auth-token': store.getters.getToken } } )
        .then( response => {
          if ( response.status == 201 ) {
            is_lock.value = false;
          } else {
            utils.show_alert_from_resp( t, response );
          }
          unlock_btn.value.stop_loading();
        } )
        .catch( e => {
          unlock_btn.value.stop_loading();
          analyse_catch( e );
        } );
    };

    const is_create_auth = () => {
      return utils.is_auth( [ API_RIGHTS.REGISTER_ACCOUNTS, API_RIGHTS.GET_RIGHTS ] );
    };

    const is_update_auth = () => {
      return utils.is_auth( [ API_RIGHTS.CHANGE_ACCOUNTS, API_RIGHTS.GET_RIGHTS ] );
    };

    const is_lock_auth = () => {
      return utils.is_auth( [ API_RIGHTS.LOCK_ACCOUNTS ] );
    };

    const is_unlock_auth = () => {
      return utils.is_auth( [ API_RIGHTS.UNLOCK_ACCOUNTS ] );
    };

    const is_create_or_update_auth = () => {
      return current_id && is_update_auth() || !current_id && is_create_auth();
    };

    const analyse_catch = e => {
      utils.analyse_catch( e, router, '/users' );
    };

    initialize();

    return { t, current_id, key_rerender, languages,
      save_btn, lock_btn, unlock_btn, is_lock, is_deleteable,
      input_firstname, input_lastname, input_email, input_password,
      input_firstname_required, input_lastname_required, input_email_required, input_password_required,
      breadcrumbs, input, email_tooltip, password_tooltip,
      is_create_auth, is_update_auth, is_lock_auth, is_unlock_auth, is_create_or_update_auth,
      click_to_save, click_to_lock, click_to_unlock };
  }
};
</script>>