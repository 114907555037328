<template>
  <div class="flex text-gray-500">
    <div v-for="( breadcrumb, index ) in breadcrumbs" :key="breadcrumb">
      <span v-if="index !== 0">&nbsp;/&nbsp;</span>
      <router-link :to="breadcrumb.route" v-if="breadcrumb.route">{{ breadcrumb.label }}</router-link>
      <span v-if="!breadcrumb.route">{{ breadcrumb.label }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'breadcrumb-component',
  components: {
  },
  props: {
    breadcrumbs: { Array, required: true },
  },
  setup() {
  }
};
</script>
