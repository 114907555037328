<template>
  <div class="flex w-full justify-center">
    <div class="m-4 w-full min-w-min text-black">
      <div class="flex pb-4">
        <div class="grow">
          <span class="text-2xl font-bold">{{ t( 'Licenses' ) }}</span>
        </div>
        <div v-if="is_create_auth()">
          <LoadingButton :label="t( 'Create license' )" :no_loading="true" @click="click_to_new()"></LoadingButton>
        </div>
      </div>
      <div class="shadow-lg">
        <EasyDataTable
          buttons-pagination alternating
          table-class-name="customize-table"
          :empty-message="t( 'No Available Data' )"
          :rows-of-page-separator-message="t( 'of' )"
          :rows-per-page-message="t( 'Show' )"
          :headers="headers"
          :items="items"
          :loading="is_loading"
          theme-color="rgb(30 64 175)"
          @click-row="click_to_row"/>
        </div>
    </div>
  </div>
  <div v-if="edit_item" class="flex w-full justify-center">
    <div class="b-2 w-1/2 min-w-min max-w-4xl rounded p-4 text-black shadow-lg">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import moment from 'moment';
import axios from '@/features/axios';
import utils from '@/features/utils';
import store from '@/store';
import router from '@/router';
import LoadingButton from '@/components/buttons/LoadingButton.vue';
import API_RIGHTS from '@/constants/rights.constants';

export default {
  name: 'licenses-view',
  components: {
    LoadingButton
  },
  setup() {
    const { t } = useI18n();
    const is_loading = ref( true );
    const headers = ref( [
      { text: t( 'Customer' ), value: 'customer', sortable: true },
      { text: t( 'Customer site' ), value: 'customer_site', sortable: true },
      { text: t( 'Product' ), value: 'product', sortable: true },
      { text: t( 'License key' ), value: 'license_key', sortable: true },
      { text: t( 'Numbers of limitations' ), value: 'numbers_of_limit', sortable: true },
      { text: t( 'Start date' ), value: 'start_date', sortable: true },
      { text: t( 'End date' ), value: 'end_date', sortable: true },
      { text: t( 'Last Activity' ), value: 'last_activity', sortable: true, width: 200 }
    ] );
    const items = ref( [] );
    const edit_item = ref( null );

    const initialize = () => {
      is_loading.value = true;
      moment.locale( store.getters.getLocale );

      axios.get( 'api/license/full', { headers: { 'auth-token': store.getters.getToken } } )
        .then( async response => {
          if ( response.status == 200 ) {
            items.value = response.data.map( item => {
              item.numbers_of_limit = get_limitations_count( item.limitations );
              item.start_date = moment( item.start_date ).format( 'LL' );
              item.end_date = moment( item.end_date ).format( 'LL' );
              item.register_date = moment( item.register_date ).format( 'LLL' );
              item.last_activity = moment( item.last_activity ).format( 'LLL' );
              item.customer = item.customer.name;
              item.customer_site = item.customer_site.name;
              item.product = item.product.name;
              return item;
            } );
            items.value = response.data;
            is_loading.value = false;
          } else {
            utils.show_alert_from_resp( t, response );
            router.push( '/' );
          }
        } )
        .catch( e => {
          utils.analyse_catch( e, router, null );
        } );
    };

    const get_limitations_count = limitations => {
      let count = 0;
      if ( limitations ) {
        let len = Object.keys( limitations ).length;
        for ( let i = 0; i < len; i++ ) {
          count += ( Number( Object.values( limitations )[ i ] ) > 0 ? 1 : 0 );
        }
      }
      return count;
    };

    const click_to_new = () => {
      router.push( '/licenses/create-new-license' );
      edit_item.value = {};
    };

    const click_to_row = item => {
      router.push( '/licenses/' + item.id );
      edit_item.value = item;
    };

    const is_create_auth = () => {
      return utils.is_auth( [ API_RIGHTS.CREATE_LICENSES ] );
    };

    initialize();

    return { t, is_loading, headers, items, edit_item, click_to_row, click_to_new, is_create_auth };
  }
};
</script>