<template>
  <div class="flex w-full justify-center">
    <div class="m-4 w-3/4 min-w-min max-w-4xl text-black">
      <div class="flex pb-4">
        <div class="grow">
          <span class="text-2xl font-bold">{{ t( 'Users' ) }}</span>
        </div>
        <div v-if="is_create_auth()">
          <LoadingButton :label="t( 'Create user' )" :no_loading="true" @click="click_to_new()"></LoadingButton>
        </div>
      </div>
      <div class="shadow-lg">
        <EasyDataTable
          buttons-pagination alternating
          table-class-name="customize-table"
          :empty-message="t( 'No Available Data' )"
          :rows-of-page-separator-message="t( 'of' )"
          :rows-per-page-message="t( 'Show' )"
          :headers="headers"
          :items="items"
          :loading="is_loading"
          theme-color="rgb(30 64 175)"
          @click-row="click_to_row">
          <template #item-account_lock="item">
            <CheckCircleIcon v-if="!item.account_lock" class="text-green-500"></CheckCircleIcon>
            <CloseCircleIcon v-if="item.account_lock" class="text-red-500"></CloseCircleIcon>
          </template>
        </EasyDataTable>
        </div>
    </div>
  </div>
  <!-- <div v-if="edit_item" class="flex w-full justify-center">
    <div class="b-2 w-1/2 min-w-min max-w-4xl rounded p-4 text-black shadow-lg">
      <router-view></router-view>
    </div>
  </div> -->
</template>

<script>
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import moment from 'moment';
import axios from '@/features/axios';
import utils from '@/features/utils';
import store from '@/store';
import router from '@/router';
import LoadingButton from '@/components/buttons/LoadingButton.vue';
import API_RIGHTS from '@/constants/rights.constants';

import CheckCircleIcon from 'vue-material-design-icons/CheckCircle.vue';
import CloseCircleIcon from 'vue-material-design-icons/CloseCircle.vue';

export default {
  name: 'users-view',
  components: {
    LoadingButton,
    CheckCircleIcon,
    CloseCircleIcon
  },
  setup() {
    const { t } = useI18n();
    const is_loading = ref( true );
    const headers = ref( [
      { text: t( 'Firstname' ), value: 'firstname', sortable: true },
      { text: t( 'Lastname' ), value: 'lastname', sortable: true },
      { text: t( 'Email' ), value: 'email', sortable: true },
      { text: t( 'Language' ), value: 'language', sortable: true, width: 100 },
      { text: t( 'Active' ), value: 'account_lock', sortable: true, width: 100 },
      { text: t( 'Last Activity' ), value: 'last_activity', sortable: true, width: 200 }
    ] );
    const items = ref( [] );
    // const edit_item = ref( null );

    const initialize = () => {
      is_loading.value = true;
      moment.locale( store.getters.getLocale );

      axios.get( 'api/user/', { headers: { 'auth-token': store.getters.getToken } } )
        .then( users => {
          items.value = users.data.map( item => {
            item.register_date = moment( item.register_date ).format( 'LLL' );
            item.last_activity = moment( item.last_activity ).format( 'LLL' );
            return item;
          } );
          is_loading.value = false;
        } )
        .catch( e => {
          utils.analyse_catch( e, router, null );
        } );
    };

    const click_to_new = () => {
      router.push( '/users/create-new-user' );
      // edit_item.value = {};
    };

    const click_to_row = item => {
      router.push( '/users/' + item.id );
      // edit_item.value = item;
    };

    const is_create_auth = () => {
      return utils.is_auth( [ API_RIGHTS.REGISTER_ACCOUNTS ] );
    };

    initialize();

    return { t, is_loading, headers, items, /*edit_item, */click_to_row, click_to_new, is_create_auth };
  }
};
</script>