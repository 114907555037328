<template>
  <div class="group relative flex focus:outline-none focus-visible:ring">
    <VueCustomTooltip :label="tooltip" position="is-bottom">
      <button :class="classes" class="inline-flex h-8 w-8 items-center justify-center focus:outline-none">
        <span v-if="!show_loading" class="text-xl" >
          <slot></slot>
        </span>
        <ClipLoader v-if="show_loading" color="#000000" size="1.3rem" class="inline-flex"></ClipLoader>
      </button>
    </VueCustomTooltip>
  </div>
</template>

<script>
import { ref } from 'vue';
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';

export default {
  name: 'icon-edit-button-component',
  components: {
    ClipLoader
  },
  props: {
    loading: { Boolean, default: false },
    tooltip: { String, default: null },
    classes: { String }
  },
  setup( props ) {
    const show_loading = ref ( props.loading );

    const start = () => {
      show_loading.value = true;
    };

    const stop = () => {
      show_loading.value = false;
    };

    return { show_loading, start, stop };
  }
};
</script>
