<template>
  <div>
    <div class="rounded border border-indigo-600 px-1" v-on:click="seen = !seen">
      <div class="locale-changer flex flex-row ">
        <div>
          <img class="max-h-[1.75em] max-w-[1.75em]" :src="require(`../../../public/flags/${locale}.png`)" />
        </div>
        <div>
          <ChevronDownIcon class="inline-block h-5 w-5 align-middle text-white" />
        </div>
      </div>
    </div>
    <div v-if="seen" class="h-15 w-13 relative left-0 top-0 mb-2 rounded bg-white pl-4 text-black">
      <div v-for="(lang, i) in langs" :key="`Lang${i}`" class="flex flex-row" v-on:click="seen = !seen; locale = `${lang.key}`; change( `${lang.key}` )">
        <img  class="max-h-[1.75em] max-w-[1.75em]" :src="require(`../../../public/flags/${lang.key}.png`)" />
        <span class="mx-2">{{ lang.name }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import store from '../../store';
import utils from '@/features/utils';
import ChevronDownIcon from 'vue-material-design-icons/ChevronDown.vue';

export default {
  name: 'select-language-component',
  components: { ChevronDownIcon },
  setup() {
    const langs = ref( utils.get_language_supported() );
    const { locale } = useI18n();
    const seen = ref( false );
    locale.value = store.getters.getLocale;

    const change = language => {
      store.dispatch( 'setLocale', { locale: language } );
    };

    return { locale, langs, seen, change };
  }
};
</script>
