<template>
  <VueFinalModal :click-to-close="false" :esc-to-close="true" attach="#modals-container" :lockScroll="false"
    class="flex items-center justify-center"
    content-class="w-1/2 bg-white rounded-xl shadow-xl">
      <!-- Header -->
      <div class="relative rounded-t-xl bg-gray-200 py-2">
        <h1 class="text-center font-bold">{{ title }}</h1>
        <div class="absolute right-[1px] top-[1px] rounded-full hover:bg-gray-400">
          <IconEditButton :tooltip="t( 'Close' )" @click="$emit('cancel');">
            <CloseIcon></CloseIcon>
          </IconEditButton>
        </div>
      </div>

      <!-- Content -->
      <slot></slot>
      <!-- Footer-->
      <hr/>
      <div class="flex">
        <div class="grow"></div>
        <div>
          <div class="flex w-full justify-end gap-4 p-2">
            <LoadingButton :label="confirmText || t( 'Save' )" @click="$emit('confirm');"></LoadingButton>
            <LoadingButton :label="cancelText || t( 'Cancel' )" @click="$emit('cancel');"></LoadingButton>
          </div>
        </div>
      </div>
  </VueFinalModal>
</template>

<script>
import { useI18n } from 'vue-i18n';
import IconEditButton from '@/components/buttons/IconEditButton.vue';

import CloseIcon from 'vue-material-design-icons/Close.vue';

export default {
  name: 'standard-modal-component',
  components: {
    CloseIcon,
    IconEditButton
  },
  emit: [ 'confirm', 'cancel' ],
  props: {
    title: { String, required: true },
    confirmText: { String, required: false, default: '' },
    cancelText: { String, required: false, default: '' }
  },
  setup() {
    const { t } = useI18n();

    return { t };
  }
};
</script>