export default {
  "All Right Reserved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Rights Reserved"])},
  "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "Confirm Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Password"])},
  "Change Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Password"])},
  "Sign-in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign-in"])},
  "Please enter your email address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your email address"])},
  "Receive code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recevoir le code"])},
  "Back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
  "Passwords do not match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwords do not match!"])},
  "Your password are changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your password are changed"])},
  "Your password are NOT changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your password are NOT changed"])},
  "Your password are NOT changed because": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your password are NOT changed because"])},
  "Please enter a valid email address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid email address."])},
  "Please enter a valid password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid password."])},
  "Please enter a valid email address and password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid email address and password."])},
  "Forgot Password?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot Password?"])},
  "Please enter the code received by email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the code received by email"])},
  "Verify code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verify code"])},
  "Please enter a valid code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid code"])},
  "The string should have a minimum length of n characters": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["The string should have a minimum length of ", _interpolate(_list(0)), " character", _interpolate(_list(1))])},
  "The string should have a maximum length of n characters": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["The string should have a maximum length of ", _interpolate(_list(0)), " character", _interpolate(_list(1))])},
  "The string should have a minimum of n uppercase letters": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["The string should have a minimum of ", _interpolate(_list(0)), " uppercase letter", _interpolate(_list(1))])},
  "The string should have a minimum of n lowercase letters": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["The string should have a minimum of ", _interpolate(_list(0)), " lowercase letter", _interpolate(_list(1))])},
  "The string should have a minimum of n digits": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["The string should have a minimum of ", _interpolate(_list(0)), " digit", _interpolate(_list(1))])},
  "The string should have a minimum of n symbols": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["The string should have a minimum of ", _interpolate(_list(0)), " symbol", _interpolate(_list(1))])}
}