<template>
  <div>
    <form>
      <div>
        <label class="mb-2 block text-white" for="email">{{ t( 'Email' ) }}</label>
        <InputText name="email" v-model="input.email"></InputText>
      </div>
      <div>
        <label class="mb-2 block text-white" for="password">{{ t( 'Password' ) }}</label>
        <InputPassword name="password" v-model="input.password"></InputPassword>
      </div>
      <div>
        <LoadingButton class="mt-3" type="button" ref="signin_btn" :label="t( 'Sign-in' )" v-on:click="login()"/>
      </div>
    </form>
    <div class="mt-4 grid grid-cols-2 justify-items-stretch text-white">
      <div class="justify-self-start">
        <SelectLanguage/>
      </div>
      <div class="justify-self-end">
        <a class="text-sm text-white hover:text-blue-400" href="#" v-on:click="click_forgot_password()">{{ t( 'Forgot Password?' ) }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, inject } from 'vue';
import { useI18n } from 'vue-i18n';
import moment from 'moment';
import router from '@/router';
import store from '@/store';
import ENUM_DISPLAY_COMPONENT from '@/enum/display.enum';
import axios from '@/features/axios';
import utils from '@/features/utils';
import * as emailValidator from 'email-validator';

export default {
  name: 'sign-in-component',
  components: {
  },
  setup( props, { emit } ) {
    const { t } = useI18n();
    const input = ref( { email: '', password: '' } );
    const cryptojs = inject( 'cryptojs' );
    const signin_btn = ref( null );

    const click_forgot_password = () => {
      emit( 'custom-event-name', { display: ENUM_DISPLAY_COMPONENT.GET_CODE } );
    };

    const login = () => {
      if ( input.value.email != '' && input.value.password != '' ) {
        if ( emailValidator.validate( input.value.email ) ) {
          axios.post( 'api/auth/sign-in', { email: input.value.email, password: cryptojs.HmacSHA1( input.value.password, process.env.VUE_APP_SECRET_PASSWORD ).toString() } )
            .then( response => {
              if ( response.status == '200' ) {
                axios.get( 'api/user/' + response.data.id, { headers: { 'auth-token': response.headers[ 'auth-token' ] } } )
                  .then( user => {
                    store.dispatch( 'setConnected', { connect: true, connect_user: user.data, token: user.headers[ 'auth-token' ] } );
                    moment.locale( store.getters.getLocale );
                    router.push( '/dashboard' );
                  } )
                  .catch( e => {
                    utils.show_catch( e );
                    signin_btn.value.stop_loading();
                  } );
              } else {
                utils.show_warning( response.data.msg );
                signin_btn.value.stop_loading();
              }
            } )
            .catch( e => {
              utils.show_catch( e );
              signin_btn.value.stop_loading();
            } );
        } else {
          utils.show_warning( t( 'Please enter a valid email address' ) );
          signin_btn.value.stop_loading();
        }
      } else {
        utils.show_warning( t( 'Please enter a valid email address and password' ) );
        signin_btn.value.stop_loading();
      }
    };

    if ( 'development' === process.env.NODE_ENV ) {
      axios.post( 'api/auth/sign-in', { email: 'admin@prod-eo.com', password: '1888c8b48c0efcff912858e3fcec859b6559d60b' } ) // ProdeoPwd!123!
        .then( response => {
          if ( response.status == '200' ) {
            axios.get( 'api/user/' + response.data.id, { headers: { 'auth-token': response.headers[ 'auth-token' ] } } )
              .then( user => {
                store.dispatch( 'setConnected', { connect: true, connect_user: user.data, token: user.headers[ 'auth-token' ] } );
                moment.locale( store.getters.getLocale );
                router.push( '/dashboard' );
              } )
              .catch( e => {
                utils.show_catch( e );
                signin_btn.value.stop_loading();
              } );
          } else {
            utils.show_warning( response.data.msg );
          }
        } )
        .catch( e => {
          utils.show_catch( e );
        } );
    }

    return { t, input, signin_btn, login, click_forgot_password };
  }
};
</script>

<style scoped lang="scss">
</style>
