import { createRouter, createWebHashHistory } from 'vue-router';
import MainView from '../views/MainView.vue';
import LoginView from '../views/SignInView.vue';
import DashboardView from '@/views/DashboardView.vue';
import LicensesView from '@/views/LicensesView.vue';
import LicenseItemView from '@/views/LicenseItemView.vue';
import CustomersView from '@/views/CustomersView.vue';
import CustomerItemView from '@/views/CustomerItemView.vue';
import CustomerSiteItemView from '@/views/CustomerSiteItemView.vue';
import ContactsView from '@/views/ContactsView.vue';
import ContactItemView from '@/views/ContactItemView.vue';
import ProductsView from '@/views/ProductsView.vue';
import ProductItemView from '@/views/ProductItemView.vue';
import UsersView from '@/views/UsersView.vue';
import UserItemView from '@/views/UserItemView.vue';
import GroupsView from '@/views/GroupsView.vue';
import GroupItemView from '@/views/GroupItemView.vue';
import ProfileView from '@/views/ProfileView.vue';
import axios from '@/features/axios';
import utils from '@/features/utils';
import API_RIGHTS from '@/constants/rights.constants';

const routes = [
  { path: '', redirect: '/dashboard' },
  { path: '/login', component: LoginView },
  { path: '/',
    component: MainView,
    children: [
      { path: 'dashboard', component: DashboardView },
      { path: 'licenses', component: LicensesView, beforeEnter: ( to, from, next ) => { auth_redirect( to, next, [ API_RIGHTS.GET_LICENSES ] ); } },
      { path: 'licenses/:id', component: LicenseItemView, beforeEnter: ( to, from, next ) => { auth_redirect_items( to, next, [ API_RIGHTS.GET_LICENSES ], [ API_RIGHTS.CREATE_LICENSES ], [ API_RIGHTS.UPDATE_LICENSES, API_RIGHTS.DELETE_LICENSES ] ); } },
      { path: 'customers', component: CustomersView, beforeEnter: ( to, from, next ) => { auth_redirect( to, next, [ API_RIGHTS.GET_CUSTOMERS, API_RIGHTS.GET_CUSTOMER_SITES ] ); } },
      { path: 'customers/:id', component: CustomerItemView, beforeEnter: ( to, from, next ) => { auth_redirect_items( to, next, [ API_RIGHTS.GET_CUSTOMERS ], [ API_RIGHTS.CREATE_CUSTOMERS ], [ API_RIGHTS.UPDATE_CUSTOMERS, API_RIGHTS.DELETE_CUSTOMERS ] ); } },
      { path: 'customer-sites/:parent/:id', component: CustomerSiteItemView, beforeEnter: ( to, from, next ) => { auth_redirect_items( to, next, [ API_RIGHTS.GET_CUSTOMER_SITES ], [ API_RIGHTS.CREATE_CUSTOMER_SITES ], [ API_RIGHTS.UPDATE_CUSTOMER_SITES, API_RIGHTS.DELETE_CUSTOMER_SITES ] ); } },
      { path: 'contacts', component: ContactsView, beforeEnter: ( to, from, next ) => { auth_redirect( to, next, [ API_RIGHTS.GET_CONTACTS ] ); } },
      { path: 'contacts/:id', component: ContactItemView, beforeEnter: ( to, from, next ) => { auth_redirect_items( to, next, [ API_RIGHTS.GET_CONTACTS ], [ API_RIGHTS.CREATE_CONTACTS ], [ API_RIGHTS.UPDATE_CONTACTS, API_RIGHTS.DELETE_CONTACTS ] ); } },
      { path: 'products', component: ProductsView, beforeEnter: ( to, from, next ) => { auth_redirect( to, next, [ API_RIGHTS.GET_PRODUCTS ] ); } },
      { path: 'products/:id', component: ProductItemView, beforeEnter: ( to, from, next ) => { auth_redirect_items( to, next, [ API_RIGHTS.GET_PRODUCTS ], [ API_RIGHTS.CREATE_PRODUCTS ], [ API_RIGHTS.UPDATE_PRODUCTS, API_RIGHTS.DELETE_PRODUCTS ] ); } },
      { path: 'users', component: UsersView, beforeEnter: ( to, from, next ) => { auth_redirect( to, next, [ API_RIGHTS.GET_ACCOUNTS ] ); } },
      { path: 'users/:id', component: UserItemView, beforeEnter: ( to, from, next ) => { auth_redirect_items( to, next, [ API_RIGHTS.GET_ACCOUNTS ], [ API_RIGHTS.REGISTER_ACCOUNTS ], [ API_RIGHTS.CHANGE_ACCOUNTS, API_RIGHTS.LOCK_ACCOUNTS, API_RIGHTS.UNLOCK_ACCOUNTS ] ); } },
      { path: 'groups', component: GroupsView, beforeEnter: ( to, from, next ) => { auth_redirect( to, next, [ API_RIGHTS.GET_GROUPS ] ); } },
      { path: 'groups/:id', component: GroupItemView, beforeEnter: ( to, from, next ) => { auth_redirect_items( to, next, [ API_RIGHTS.GET_GROUPS ], [ API_RIGHTS.CREATE_GROUPS ], [ API_RIGHTS.UPDATE_GROUPS, API_RIGHTS.DELETE_GROUPS ] ); } },
      { path: 'profile', component: ProfileView },
      { path: '/:pathMatch(.*)*', redirect: '/' }
    ]
  }
];

const auth_redirect = ( to, next, keys ) => {
  return ( utils.is_auth( keys ) ? next() : next( { path: '/dashboard', query: { returnUrl: to.path } } ) );
};

const auth_redirect_items = ( to, next, get_keys, create_keys, update_keys ) => {
  let auth = utils.is_auth( get_keys );
  if ( to.params.id.startsWith( 'create-' ) ) {
    auth &= utils.is_auth( create_keys );
  } else {
    auth |= utils.is_auth( update_keys );
  }
  return ( auth ? next() : next( { path: '/dashboard', query: { returnUrl: to.path } } ) );
};

const router = createRouter( {
  history: createWebHashHistory(),
  routes
} );

router.beforeEach( ( to, from, next ) => {
  axios.get( 'api/auth/is-sign-in/' )
    .then( response => {
      if ( response.data.signin == true ) {
        if ( to.path === '/login' || to.path === '/resetpwd' || to.path === '/coderesetpwd' ) {
          return next( {
            path: '/',
            query: { returnUrl: to.path }
          } );
        }
      } else {
        if ( to.path !== '/login' && to.path !== '/resetpwd' && to.path !== '/coderesetpwd' ) {
          return next( {
            path: '/login',
            query: { returnUrl: to.path }
          } );
        }
      }
      next();
    } )
    .catch( e => {
      if ( e.response && e.response.status == 406 ) {
        if ( to.path !== '/login' && to.path !== '/resetpwd' && to.path !== '/coderesetpwd' ) {
          utils.show_alert( e.response.data.msg );
          return next( {
            path: '/login',
            query: { returnUrl: to.path }
          } );
        } else {
          next();
        }
      } else {
        utils.show_catch( e );
      }
    } );
} );

export default router;
