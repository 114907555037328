<template>
  <StandardModal ref="standard_modal" :title="t( 'Add new users' )" @confirm="save( select )">
    <div class="p-4 text-center text-gray-500" >
      <Multiselect v-if="!loading" v-model="select" :options="options" mode="tags" :searchable="true" class="customize-multiselect h-8"
        :noResultsText="t( 'No results found' )" :noOptionsText="t( 'The list is empty' )"></Multiselect>
      <ClipLoader v-if="loading" color="#000000" size="2rem" class="inline-flex w-full justify-center"></ClipLoader>
    </div>
  </StandardModal>
</template>

<script>
import { ref, toRaw } from 'vue';
import { useI18n } from 'vue-i18n';
import store from '@/store';
import axios from '@/features/axios';
import utils from '@/features/utils';
import StandardModal from './StandardModal.vue';
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';

export default {
  name: 'add-users-modal-component',
  components: {
    StandardModal,
    ClipLoader
  },
  props: {
    users: { Array, required: true }
  },
  emit: [ 'save' ],
  setup( props, { emit } ) {
    const { t } = useI18n();
    const standard_modal = ref( null );
    const loading = ref( false );

    const select = ref( null );
    const options = ref( null );

    const initialize = () => {
      loading.value = true;
      axios.get( 'api/user/', { headers: { 'auth-token': store.getters.getToken } } )
        .then( response => {
          let users_id = props.users.map( item => { return item.id; } );
          options.value = response.data.filter( item => !users_id.includes( item.id ) )
            .map( item => { return { value: item.id, label: utils.get_user_fullname( item ), ...item }; } );
        } )
        .catch( e => {
          utils.show_catch( e );
        } )
        .finally( () => {
          loading.value = false;
        } );
    };

    const save = sel => {
      let values = options.value.filter( item => sel.includes( item.value ) ).map( item => { return toRaw( item ); } );
      emit( 'save', values );
    };

    initialize();

    return { t, loading, standard_modal, select, options, save };
  }
};
</script>