<template>
  <button class="inline-flex h-9 w-full items-center justify-center rounded px-4 font-bold" :class="colors"
    type="button" :disabled="loading" @click="start_loading()">
    <span v-if="!loading" class="text-base">{{ label }}</span>
    <ClipLoader v-if="loading" color="#ffffff" size="1.3rem" class="inline-flex"></ClipLoader>
  </button>
</template>

<script>
import { ref } from 'vue';
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';

export default {
  name: 'loading-button-component',
  components: {
    ClipLoader
  },
  props: {
    label: { String, required: true },
    colors: { String, required: false, default: 'bg-blue-800 hover:bg-blue-700 text-white' },
    no_loading: { Boolean, default: false }
  },
  setup( props ) {
    const loading = ref( false );

    const start_loading = () => {
      if ( !props.no_loading ) {
        loading.value = true;
      }
    };

    const stop_loading = () => {
      loading.value = false;
    };

    return {
      loading,
      start_loading,
      stop_loading
    };
  }
};
</script>
