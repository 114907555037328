import { useNotificationStore } from '@dafcoe/vue-notification';
import store from '@/store';

const { setNotification } = useNotificationStore();

const VueNotificationOptions = Object.freeze( {
  'message': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
  'type': 'info',
  'showIcon': true,
  'dismiss': {
    'manually': true,
    'automatically': true
  },
  'duration': 5000,
  'showDurationProgress': true,
  'appearance': 'dark'
} );

export default {
  get_language_supported() {
    return [ { key: 'en', name: 'English' }, { key: 'fr', name: 'Français' } ];
  },
  get_default_language() {
    return 'fr';
  },
  get_api_base_url() {
    if ( process.env.VUE_APP_API_BASE_URL !== '' ) {
      return process.env.VUE_APP_API_BASE_URL;
    }
    return window.location.protocol + '//api.' + window.location.hostname;
  },
  show_sucess( msg ) {
    setNotification( { ...VueNotificationOptions, ...{ 'message': msg, 'type': 'sucess' } } );
  },
  show_warning( msg ) {
    console.warn( msg );
    setNotification( { ...VueNotificationOptions, ...{ 'message': msg, 'type': 'warning' } } );
  },
  show_alert( msg ) {
    console.error( msg );
    setNotification( { ...VueNotificationOptions, ...{ 'message': msg, 'type': 'alert' } } );
  },
  show_catch( e ) {
    console.error( e );
    let msg = e.response ? ( e.response.data.msg ?? e.message ) : e.message;
    setNotification( { ...VueNotificationOptions, ...{ 'message': msg, 'type': 'alert' } } );
  },
  show_alert_from_resp( t, response ) {
    let message = t( 'Error' ) + ' ' + response.data.err + ' - ' + response.data.msg;
    this.show_alert( message );
  },
  analyse_catch( e, router, parent ) {
    this.show_catch( e );
    if ( e.response && e.response.status == 406 || !parent ) {
      router.push( '/login' );
    } else {
      router.push( parent );
    }
  },
  is_auth( keys ) {
    let auth = false;
    if ( store.getters.get_connected_user && store.getters.get_connected_user.rights ) {
      auth = keys.length > 0 ? true : false;
      keys.forEach( key => {
        auth &= store.getters.get_connected_user.rights.includes( key );
      } );
    }

    return auth;
  },
  get_user_fullname( user ) {
    return user.firstname + ' ' + user.lastname.toUpperCase();
  },
  get_contact_fullname( contact ) {
    let fullname = this.get_user_fullname( contact );
    if ( contact.email ) {
      fullname += ' (' + contact.email.toLowerCase() + ')';
    }
    return fullname;
  },
  get_params_id( id ) {
    if ( id.startsWith( 'create' ) ) {
      return null;
    } else {
      return id;
    }
  }
};
