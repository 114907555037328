<template>
  <div class="flex w-full justify-center">
    <div class="m-4 w-1/2 min-w-min max-w-4xl text-black">
      <Breadcrumb :breadcrumbs="breadcrumbs" class="mb-4"/>
      <div class="flex pb-4">
        <div class="grow">
          <span v-show="!current_id" class="text-2xl font-bold">{{ t( 'Create license' ) }}</span>
          <span v-show="current_id" class="text-2xl font-bold">{{ t( 'Update license' ) }}</span>
        </div>
        <div class="flex gap-2">
          <LoadingButton v-if="current_id && is_delete_auth()" :label="t( 'Delete' )" ref="delete_btn" @click="click_to_delete()" colors="bg-red-600 text-white hover:bg-red-700"></LoadingButton>
          <LoadingButton v-if="is_create_or_update_auth()" :label="t( 'Save' )" ref="save_btn" @click="click_to_save()"></LoadingButton>
        </div>
      </div>
      <!-- Customer -->
      <div class="mb-4 rounded-md shadow-md">
        <label class="mb-2 block bg-gray-100 p-2 font-bold" for="customer">{{ t( 'Customer' ) }}</label>
        <Multiselect v-if="!loading_customer" v-model="select_customer" :options="options_customer" mode="single" :searchable="true" class="customize-multiselect h-8"
          :noResultsText="t( 'No results found' )" :noOptionsText="t( 'The list is empty' )" :canClear="false" valueProp="id" label="name" @change="change_customer"></Multiselect>
        <ClipLoader v-if="loading_customer" color="#000000" size="2rem" class="inline-flex w-full justify-center"></ClipLoader>
      </div>
      <!-- Customer site -->
      <div class="mb-4 rounded-md shadow-md">
        <label class="mb-2 block bg-gray-100 p-2 font-bold" for="customer_site">{{ t( 'Customer site' ) }}</label>
        <Multiselect v-if="!loading_customer_site" v-model="select_customer_site" :options="options_customer_site" mode="single" :searchable="true" class="customize-multiselect h-8"
          :noResultsText="t( 'No results found' )" :noOptionsText="t( 'The list is empty' )" :canClear="false" valueProp="id" label="name"></Multiselect>
        <ClipLoader v-if="loading_customer_site" color="#000000" size="2rem" class="inline-flex w-full justify-center"></ClipLoader>
      </div>
      <!-- Product -->
      <div class="mb-4 rounded-md shadow-md">
        <label class="mb-2 block bg-gray-100 p-2 font-bold" for="product">{{ t( 'Product' ) }}</label>
        <Multiselect v-if="!loading_product" v-model="select_product" :options="options_product" mode="single" :searchable="true" class="customize-multiselect h-8"
          :noResultsText="t( 'No results found' )" :noOptionsText="t( 'The list is empty' )" :canClear="false" valueProp="id" label="name" @change="change_product"></Multiselect>
        <ClipLoader v-if="loading_product" color="#000000" size="2rem" class="inline-flex w-full justify-center"></ClipLoader>
      </div>
      <!-- Licence key -->
      <div class="mb-4 rounded-md shadow-md">
        <label class="mb-2 block bg-gray-100 p-2 font-bold" for="license_key">{{ t( 'License key' ) }}</label>
        <div class="flex">
          <div class="grow">
            <InputTextRequired ref="input_license_key" name="license_key" v-model="input.license_key" :show_required="input_license_key_required" @change="input_license_key_required = false" :key="licence_key_rerender"></InputTextRequired>
          </div>
          <div class="mx-2">
            <IconEditButton :tooltip="t( 'Generate license key' )" @click="click_generate_key">
              <KeyOutlineIcon></KeyOutlineIcon>
            </IconEditButton>
          </div>
        </div>
      </div>
      <!-- Limitations -->
      <div class="mb-4 rounded-md shadow-md">

        <div v-show="limitations_product == ENUM_PRODUCT_COMPONENT.KAMISHIBAI">
          <label class="mb-2 block bg-gray-100 p-2 font-bold" for="limitations">{{ t( 'Limitations of kamishibai' ) }}</label>
          <div class="ml-2 flex">
            <div class="self-center">{{ t( 'Numbers of tables' ) }}</div>
            <InputNumberRequired ref="input_max_tables" min="0" name="max_tables" v-model="input.max_tables" :show_required="input_max_tables_required" @change="input_max_tables_required = false" :key="limitations_rerender"></InputNumberRequired>
          </div>
        </div>

        <div v-show="limitations_product == ENUM_PRODUCT_COMPONENT.FLASHMEETING">
          <label class="mb-2 block bg-gray-100 p-2 font-bold" for="limitations">{{ t( 'Limitations of flashmeeting' ) }}</label>
          <div class="ml-2 flex">
            <div class="self-center">{{ t( 'Numbers of tables' ) }}</div>
            <InputNumberRequired ref="input_max_tables" min="0" name="max_tables" v-model="input.max_tables" :show_required="input_max_tables_required" @change="input_max_tables_required = false" :key="limitations_rerender"></InputNumberRequired>
          </div>
        </div>

        <div v-show="limitations_product == ENUM_PRODUCT_COMPONENT.RESOLUTION">
          <label class="mb-2 block bg-gray-100 p-2 font-bold" for="limitations">{{ t( 'Limitations of resolution' ) }}</label>
          <div class="ml-2 flex">
            <div class="self-center">{{ t( 'Numbers of resolutions' ) }}</div>
            <InputNumberRequired ref="input_max_resolutions" min="0" name="max_resolutions" v-model="input.max_resolutions" :show_required="input_max_resolutions_required" @change="input_max_resolutions_required = false" :key="limitations_rerender"></InputNumberRequired>
          </div>
        </div>
      </div>

      <!-- Start Date -->
      <div class="mb-4 rounded-md shadow-md">
        <label class="mb-2 block bg-gray-100 p-2 font-bold" for="limitations">{{ t( 'Start date' ) }}</label>
        <Datepicker v-model="input.start_date" :enable-time-picker="false"
          :select-text="t('datetime-picker-select')" :cancel-text="t('datetime-picker-cancel')"
          :locale="params.locale" :format="params.format" :is-24="params.is_24h"
          :key="date_rerender">
          <template #input-icon>
            <CalendarBlankOutline></CalendarBlankOutline>
          </template>
        </Datepicker>
      </div>

      <!-- End Date -->
      <div class="mb-4 rounded-md shadow-md">
        <label class="mb-2 block bg-gray-100 p-2 font-bold" for="limitations">{{ t( 'Start date' ) }}</label>
        <Datepicker v-model="input.end_date" :enable-time-picker="false"
          :select-text="t('datetime-picker-select')" :cancel-text="t('datetime-picker-cancel')"
          :locale="params.locale" :format="params.format" :is-24="params.is_24h"
          :key="date_rerender">
          <template #input-icon>
            <CalendarBlankOutline></CalendarBlankOutline>
          </template>
        </Datepicker>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useModal } from 'vue-final-modal';
import { useRoute } from 'vue-router';
import cryptoRandomStringAsync from 'crypto-random-string';
import moment from 'moment';
import router from '@/router';
import store from '@/store';
import axios from '@/features/axios';
import utils from '@/features/utils';
import API_RIGHTS from '@/constants/rights.constants';
import ConfirmModal from '@/components/modal/ConfirmModal.vue';
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
import IconEditButton from '@/components/buttons/IconEditButton.vue';

import KeyOutlineIcon from 'vue-material-design-icons/KeyOutline.vue';
import CalendarBlankOutline from 'vue-material-design-icons/CalendarBlankOutline.vue';

import ENUM_PRODUCT_COMPONENT from '@/enum/product.enum';

export default {
  name: 'product-item-view',
  components: {
    ClipLoader,
    IconEditButton,
    KeyOutlineIcon,
    CalendarBlankOutline
  },
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const current_id = utils.get_params_id( route.params.id );
    const breadcrumbs = ref ( [
      { label: t( 'Administration' ) },
      { label: t( 'Licenses' ), route: '/licenses' }
    ] );
    const params = ref( {
      is_24h: true,
      locale: store.getters.getLocale,
      format: 'dd/MM/yyyy'
    } );
    const input = ref( { license_key: '', limitations: { max_tables: 0, max_resolutions: 0 }, start_date: '', end_date: '' } );
    const save_btn = ref( null );
    const delete_btn = ref( null );
    const input_license_key = ref( null );
    const input_license_key_required = ref( null );
    const input_max_tables = ref( null );
    const input_max_tables_required = ref( null );
    const input_max_resolutions = ref( null );
    const input_max_resolutions_required = ref( null );
    const licence_key_rerender = ref ( 0 );
    const limitations_rerender = ref ( 0 );
    const date_rerender = ref ( 0 );

    const loading_customer = ref( true );
    const select_customer = ref( null );
    const options_customer = ref( [] );

    const loading_customer_site = ref( false );
    const select_customer_site = ref( null );
    const options_customer_site = ref( [] );

    const loading_product = ref( true );
    const select_product = ref( null );
    const options_product = ref( [] );

    const limitations_product = ref ( null ) ;

    const force_licence_key_rerender = () => {
      licence_key_rerender.value += 1;
    };

    const force_limitations_rerender = () => {
      limitations_rerender.value += 1;
    };

    const force_date_rerender = () => {
      date_rerender.value += 1;
    };

    const initialize = () => {
      moment.locale( store.getters.getLocale );
      params.value.format = moment.localeData().longDateFormat( 'L' ).replaceAll( 'D', 'd' ).replaceAll( 'Y', 'y' );

      axios.get( 'api/customer/', { headers: { 'auth-token': store.getters.getToken } } )
        .then( async response => {
          if ( response.status == 200 ) {
            options_customer.value = response.data;
            loading_customer.value = false;
          } else {
            utils.show_alert_from_resp( t, response );
            router.push( '/licenses' );
          }
        } )
        .catch( e => {
          analyse_catch( e );
        } );

      axios.get( 'api/product/', { headers: { 'auth-token': store.getters.getToken } } )
        .then( async response => {
          if ( response.status == 200 ) {
            options_product.value = response.data;
            loading_product.value = false;
            change_product( null );
          } else {
            utils.show_alert_from_resp( t, response );
            router.push( '/licenses' );
          }
        } )
        .catch( e => {
          analyse_catch( e );
        } );

      if ( current_id ) {
        axios.get( 'api/license/' + current_id, { headers: { 'auth-token': store.getters.getToken } } )
          .then( async response => {
            if ( response.status == 200 ) {
              select_customer.value = response.data.customer;
              select_product.value = response.data.product;
              input.value.license_key = response.data.license_key;
              input.value.max_tables = response.data.limitations.max_tables ?? 0;
              input.value.max_resolutions = response.data.limitations.max_resolutions ?? 0;
              input.value.start_date = response.data.start_date;
              input.value.end_date = response.data.end_date;

              change_customer( response.data.customer ).then( () => select_customer_site.value = response.data.customer_site );
              change_product( response.data.product );

              force_licence_key_rerender();
              force_limitations_rerender();
              force_date_rerender();
            } else {
              utils.show_alert_from_resp( t, response );
              router.push( '/licences' );
            }
          } )
          .catch( e => {
            analyse_catch( e );
          } );
      }
    };

    const change_customer = customer_id => {
      return new Promise( resolve => {
        loading_customer_site.value = false;
        axios.get( 'api/customer_site/', { headers: { 'auth-token': store.getters.getToken } } )
          .then( async response => {
            if ( response.status == 200 ) {
              select_customer_site.value = null;
              options_customer_site.value = response.data.filter( item => item.customers == customer_id );
              loading_customer_site.value = false;
              resolve();
            } else {
              utils.show_alert_from_resp( t, response );
              router.push( '/licenses' );
            }
          } )
          .catch( e => {
            analyse_catch( e );
          } );
      } );
    };

    const change_product = product_id => {

      if ( options_product.value.length == 0 ) {
        return;
      }
      let values = options_product.value;

      if ( !product_id ) {
        product_id = select_product.value;
      }

      if ( product_id == ( values.filter( item => item.name == ENUM_PRODUCT_COMPONENT.KAMISHIBAI ) )[ 0 ].id ) {
        limitations_product.value = ENUM_PRODUCT_COMPONENT.KAMISHIBAI;
      } else if ( product_id == ( values.filter( item => item.name == ENUM_PRODUCT_COMPONENT.RESOLUTION ) )[ 0 ].id ) {
        limitations_product.value = ENUM_PRODUCT_COMPONENT.RESOLUTION;
      } else {
        limitations_product.value = ENUM_PRODUCT_COMPONENT.FLASHMEETING;
      }
    };

    const click_generate_key = () => {
      if ( current_id ) {
        confirm_generate_key_modal.open();
      } else {
        generate_key();
      }
    };

    const confirm_generate_key_modal = useModal( {
      component: ConfirmModal,
      attrs: {
        title: t( 'Generate license key' ),
        message: t( 'Confirm generate key' ),
        onConfirm() {
          generate_key();
          confirm_generate_key_modal.close();
        },
        onCancel() {
          confirm_generate_key_modal.close();
        }
      }
    } );

    const generate_key = () => {
      input.value.license_key = cryptoRandomStringAsync( { length: 32, characters: 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789' } );
      force_licence_key_rerender();
    };

    const click_to_save = () => {
      if ( !select_customer.value ) {
        save_btn.value.stop_loading();
        return;
      }

      if ( !select_product.value ) {
        save_btn.value.stop_loading();
        return;
      }

      if ( typeof ( input.value.license_key ) != 'string' || input.value.license_key.trim() == '' ) {
        input_license_key_required.value = true;
        input_license_key.value.focus();
        save_btn.value.stop_loading();
        return;
      }

      if ( input.value.max_tables < 0 ) {
        input_max_tables_required.value = true;
        input_max_tables.value.focus();
        save_btn.value.stop_loading();
        return;
      }

      if ( input.value.max_resolutions < 0 ) {
        input_max_resolutions_required.value = true;
        input_max_resolutions.value.focus();
        save_btn.value.stop_loading();
        return;
      }

      input_license_key_required.value = false;

      if ( current_id ) {
        update_license();
      } else {
        create_new_license();
      }
    };

    const create_new_license = () => {
      let limitations =  {
        max_tables: limitations_product.value = ENUM_PRODUCT_COMPONENT.FLASHMEETING ? input.value.max_tables : undefined,
        max_resolutions: limitations_product.value = ENUM_PRODUCT_COMPONENT.RESOLUTION ? input.value.max_resolutions : undefined
      };

      let data = {
        customers: select_customer_site.value ? undefined : select_customer.value,
        customer_sites: select_customer_site.value ?? undefined,
        products: select_product.value,
        license_key: input.value.license_key,
        limitations: limitations,
        start_date: moment( input.value.start_date ).format( 'YYYY-MM-DD' ),
        end_date: moment( input.value.end_date ).format( 'YYYY-MM-DD' )
      };

      axios.post( 'api/license/', data, { headers: { 'auth-token': store.getters.getToken } } )
        .then( response => {
          if ( response.status == 200 ) {
            router.push( '/licenses' );
          } else {
            utils.show_alert_from_resp( t, response );
          }
          save_btn.value.stop_loading();
        } )
        .catch( e => {
          save_btn.value.stop_loading();
          analyse_catch( e );
        } );
    };

    const update_license = () => {
      let limitations =  {
        max_tables: limitations_product.value == ENUM_PRODUCT_COMPONENT.FLASHMEETING ? input.value.max_tables : undefined,
        max_resolutions: limitations_product.value == ENUM_PRODUCT_COMPONENT.RESOLUTION ? input.value.max_resolutions : undefined
      };

      console.log( limitations );
      let data = {
        customers: select_customer_site.value ? undefined : select_customer.value,
        customer_sites: select_customer_site.value ?? undefined,
        products: select_product.value,
        license_key: input.value.license_key,
        limitations: limitations,
        start_date: moment( input.value.start_date ).format( 'YYYY-MM-DD' ),
        end_date: moment( input.value.end_date ).format( 'YYYY-MM-DD' )
      };

      axios.put( 'api/license/' + current_id, data, { headers: { 'auth-token': store.getters.getToken } } )
        .then( response => {
          if ( response.status == 201 ) {
            router.push( '/licenses' );
          } else {
            utils.show_alert_from_resp( t, response );
          }
          save_btn.value.stop_loading();
        } )
        .catch( e => {
          save_btn.value.stop_loading();
          analyse_catch( e );
        } );
    };

    const click_to_delete = () => {
      confirm_delete_modal.open();
    };

    const confirm_delete_modal = useModal( {
      component: ConfirmModal,
      attrs: {
        title: t( 'Delete license' ),
        message: t( 'Confirm delete license' ),
        onConfirm() {
          delete_license();
          confirm_delete_modal.close();
        },
        onCancel() {
          confirm_delete_modal.close();
          delete_btn.value.stop_loading();
        }
      }
    } );

    const delete_license = () => {
      axios.delete( 'api/license/' + current_id, { headers: { 'auth-token': store.getters.getToken } } )
        .then( response => {
          if ( response.status == 201 ) {
            router.push( '/licenses' );
          } else {
            utils.show_alert_from_resp( t, response );
          }
          delete_btn.value.stop_loading();
        } )
        .catch( e => {
          delete_btn.value.stop_loading();
          analyse_catch( e );
        } );
    };

    const is_create_auth = () => {
      return utils.is_auth( [ API_RIGHTS.CREATE_LICENSES, API_RIGHTS.GET_CUSTOMERS, API_RIGHTS.GET_CUSTOMER_SITES, API_RIGHTS.GET_PRODUCTS ] );
    };

    const is_update_auth = () => {
      return utils.is_auth( [ API_RIGHTS.UPDATE_LICENSES, API_RIGHTS.GET_CUSTOMERS, API_RIGHTS.GET_CUSTOMER_SITES, API_RIGHTS.GET_PRODUCTS ] );
    };

    const is_delete_auth = () => {
      return utils.is_auth( [ API_RIGHTS.DELETE_LICENSES ] );
    };

    const is_create_or_update_auth = () => {
      return current_id && is_update_auth() || !current_id && is_create_auth();
    };

    const analyse_catch = e => {
      utils.analyse_catch( e, router, '/licenses' );
    };

    initialize();

    return { t, current_id, save_btn, delete_btn, breadcrumbs, input,
      params, date_rerender,
      licence_key_rerender, limitations_rerender,
      input_license_key, input_license_key_required,
      input_max_tables, input_max_tables_required,
      input_max_resolutions, input_max_resolutions_required,
      loading_customer, select_customer, options_customer, change_customer,
      loading_product, select_product, options_product, change_product, limitations_product,
      loading_customer_site, select_customer_site, options_customer_site,
      is_create_auth, is_update_auth, is_delete_auth, is_create_or_update_auth,
      click_to_save, click_to_delete, click_generate_key, ENUM_PRODUCT_COMPONENT };
  }
};
</script>>