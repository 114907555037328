<template>
  <div class="h-full w-full p-20" :style="bg_image_dynamic" >
    <div class="bg-card-sing-in m-auto w-full max-w-xs rounded-xl p-5">
      <div>
        <img class="h-15 mx-auto mb-5" src="./../../public/img/logo_small.png" />
      </div>
      <div>
        <SignIn v-show="displayComponent == ENUM_DISPLAY_COMPONENT.SIGNIN" @custom-event-name="change_component" :key="key_rerender"></SignIn>
        <GetCode v-show="displayComponent == ENUM_DISPLAY_COMPONENT.GET_CODE" @custom-event-name="change_component" :key="key_rerender"></GetCode>
        <VerifyCode v-show="displayComponent == ENUM_DISPLAY_COMPONENT.VERIFY_CODE" @custom-event-name="change_component" :user_id="user_id" :key="key_rerender"></VerifyCode>
        <ChangePassword v-show="displayComponent == ENUM_DISPLAY_COMPONENT.CHANGE_PWD" @custom-event-name="change_component" :user_id="user_id" :code="code" :key="key_rerender"></ChangePassword>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import SignIn from '../components/sign-in/SignIn';
import GetCode from '../components/sign-in/GetCode';
import VerifyCode from '../components/sign-in/VerifyCode';
import ChangePassword from '@/components/sign-in/ChangePassword';
import ENUM_DISPLAY_COMPONENT from '../enum/display.enum.js';

export default {
  name: 'sign-in-view',
  components: {
    SignIn,
    GetCode,
    VerifyCode,
    ChangePassword
  },
  setup() {
    const displayComponent = ref( ENUM_DISPLAY_COMPONENT.SIGNIN );
    const user_id = ref( '' );
    const code = ref( '' );
    const key_rerender = ref ( 0 );

    const bg_image_dynamic = computed( () => {
      let num = Math.floor( Math.random() * Math.floor( 1 ) ) + 1 ;
      let random = ( '000' + num ).slice( -3 );
      let bgImg = require( './../../public/background/background-' + random + '.jpg' );
      return {
        'background': 'url(' + bgImg + ')',
        'background-size': 'cover'
      };
    } );

    const force_init_rerender = () => {
      key_rerender.value += 1;
    };

    const change_component = payload => {
      user_id.value = payload.id;
      code.value = payload.code;
      displayComponent.value = payload.display;
      force_init_rerender();
    };

    return { key_rerender, displayComponent, ENUM_DISPLAY_COMPONENT, change_component, bg_image_dynamic, user_id, code };
  }
};
</script>

<style scoped lang="scss">
  .bg-card-sing-in {
    background: #eeeeee;
    box-shadow: 0 5px 5px rgba(0,0,0,.4);
    background-color: black
  }
</style>