import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';
import VueCryptojs from 'vue-cryptojs';
import VueCustomTooltip from '@adamdehaven/vue-custom-tooltip';
import VueNotificationList from '@dafcoe/vue-notification';
import Vue3EasyDataTable from 'vue3-easy-data-table';
import Multiselect from '@vueform/multiselect';
import Datepicker from '@vuepic/vue-datepicker';

import { VueFinalModal } from 'vue-final-modal';
import { createVfm } from 'vue-final-modal';

import './assets/tailwind.css';
import './assets/custom.css';
import '@vuepic/vue-datepicker/dist/main.css';
import '@dafcoe/vue-notification/dist/vue-notification.css';
import 'vue3-easy-data-table/dist/style.css';
import 'vue-final-modal/style.css';
import '@vueform/multiselect/themes/default.css';

import SelectLanguage from './components/objects/SelectLanguage';
import LoadingButton from './components/buttons/LoadingButton.vue';
import InputText from './components/inputs/InputText.vue';
import InputTextRequired from './components/inputs/InputTextRequired.vue';
import InputNumber from './components/inputs/InputNumber.vue';
import InputNumberRequired from './components/inputs/InputNumberRequired.vue';
import InputPassword from './components/inputs/InputPassword.vue';
import InputPasswordRequired from './components/inputs/InputPasswordRequired.vue';
import Field from './components/inputs/Field.vue';
import Breadcrumb from '@/components/objects/Breadcrumb.vue';

const vfm = createVfm();

createApp( App )
  .use( i18n )
  .use( store )
  .use( router )
  .use( VueCryptojs )
  .use( VueNotificationList )
  .use( VueCustomTooltip, {
    name: 'VueCustomTooltip',
    color: '#fff',
    background: '#000',
    borderRadius: 100,
    fontWeight: 400,
  } )
  .use( vfm )
  .component( 'SelectLanguage', SelectLanguage )
  .component( 'LoadingButton', LoadingButton )
  .component( 'InputText', InputText )
  .component( 'InputPassword', InputPassword )
  .component( 'InputNumber', InputNumber )
  .component( 'InputTextRequired', InputTextRequired )
  .component( 'InputPasswordRequired', InputPasswordRequired )
  .component( 'InputNumberRequired', InputNumberRequired )
  .component( 'Field', Field )
  .component( 'Breadcrumb', Breadcrumb )
  .component( 'EasyDataTable', Vue3EasyDataTable )
  .component( 'VueFinalModal', VueFinalModal )
  .component( 'Multiselect', Multiselect )
  .component( 'Datepicker', Datepicker )
  .mount( '#app' );