<template>
  <div class="relative flex">
    <InputText name="value" v-model="field_value" :readonly="readonly" @input="update($event.target.value)"
      @keyup.enter="confirm()" @focus="editable = true" @blur="(event) => { blur(event); }"
      :key="key_rerender"></InputText>
    <div v-if="editable" class="absolute -bottom-8 right-0 flex gap-1 text-gray-500">
      <div class="z-50 rounded bg-gray-100 shadow-md">
        <IconEditButton classes="noblur" @click="confirm()">
          <Check></Check>
        </IconEditButton>
      </div>
      <div class="z-50 rounded bg-gray-100 shadow-md">
        <IconEditButton classes="noblur" @click="cancel()">
          <Close></Close>
        </IconEditButton>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import IconEditButton from '../buttons/IconEditButton.vue';
import Check from 'vue-material-design-icons/Check.vue';
import Close from 'vue-material-design-icons/Close.vue';

export default {
  name: 'field-confirm-component',
  components: {
    IconEditButton,
    Check,
    Close
  },
  props: {
    modelValue: { String, required: true },
    field_key: { String, required: false, default: 'field_key' },
    readonly: { Boolean, required: false, default: false }
  },
  emit: [ 'change' ],
  setup( props, { emit } ) {
    const field_value = ref( props.modelValue );
    const initial_val = ref( props.modelValue );
    const editable = ref ( false );
    const key_rerender = ref ( 0 );

    const force_init_rerender = () => {
      key_rerender.value += 1;
    };

    const blur = event => {
      if ( event.relatedTarget && event.relatedTarget.className.includes( 'noblur' ) ) {
        event.preventDefault = false;
      } else {
        cancel();
        editable.value = false;
      }
    };

    const update = value => {
      field_value.value = value;
    };

    const confirm = () => {
      initial_val.value = field_value.value;
      emit( 'change', field_value.value, props.field_key );
      editable.value = false;
    };

    const cancel = () => {
      field_value.value = initial_val.value;
      force_init_rerender();
      editable.value = false;
    };

    return { key_rerender, field_value, editable, blur, update, confirm, cancel };
  }
};
</script>
