<template>
  <div class="px-2 pb-2">
    <div class="flex flex-row rounded-md">
      <InputStd ref="input_std" :type="type" name="value" v-model="val" @input="$emit('update:modelValue', $event.target.value)" @focus="focused()" @blur="focused()"></InputStd>
      <button type="button" class="mb-2 h-9 justify-center border-b-2 border-blue-500 px-2 pb-1 outline-none" :class="get_class_focused()">
        <EyeOutlineIcon v-if="!show_password" class="inline-block h-5 w-5 align-middle text-black" @click="switch_mode"/>
        <EyeOffOutlineIcon v-if="show_password" class="inline-block h-5 w-5 align-middle text-black" @click="switch_mode"/>
      </button>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import InputStd from './InputStd.vue';
import EyeOutlineIcon from 'vue-material-design-icons/EyeOutline.vue';
import EyeOffOutlineIcon from 'vue-material-design-icons/EyeOffOutline.vue';

export default {
  name: 'input-password-component',
  components: {
    InputStd,
    EyeOutlineIcon,
    EyeOffOutlineIcon
  },
  props: {
    modelValue: { String, required: true }
  },
  setup() {
    const input_std = ref ( null );
    const val = ref( '' );
    const type = ref( 'password' );
    const show_password = ref( false );
    const is_focus = ref( false );

    const switch_mode = () => {
      type.value = show_password.value ? 'password' : 'text';
      show_password.value = !show_password.value;
    };

    const focus = () => {
      input_std.value.focus();
    };

    const focused = () => {
      is_focus.value = !is_focus.value;
    };

    const get_class_focused = () => {
      return is_focus.value ? 'bg-gray-300' : 'bg-white';
    };

    return { input_std, val, type, show_password, focus, switch_mode, focused, get_class_focused };
  }
};
</script>
