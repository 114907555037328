<template>
  <div class="flex whitespace-nowrap pl-2" :class="active ? 'bg-blue-500' : ''" >
    <div class="mr-2 w-7 py-2">
      <slot></slot>
    </div>
    <div class="flex-1 py-2 pr-8">
      {{ title }}
    </div>
    <div v-if="active" class="ml-2 w-8 self-center border-y-[10px] border-r-[14px] border-y-transparent border-r-white" />
  </div>
</template>

<script>
export default {
  name: 'menu-entry',
  components: {
  },
  props: {
    title: { String, required: true },
    active: { Boolean, required: true }
  },
  setup() {
    return {
    };
  }
};
</script>
