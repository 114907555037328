export default {
  "All Right Reserved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous droits réservés"])},
  "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse e-mail"])},
  "Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
  "Confirm Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer le mot de passe"])},
  "Change Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changer mon mot de passe"])},
  "Sign-in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion"])},
  "Please enter your email address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez saisir votre adresse email"])},
  "Receive code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recevoir le code"])},
  "Back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour"])},
  "Passwords do not match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les mots de passe ne correspondent pas !"])},
  "Your password are changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre mot de passe à été modifié."])},
  "Your password are NOT changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre mot de passe n'as pas pu être modifié."])},
  "Your password are NOT changed because": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Votre mot de passe n'as pas pu être modifié : ", _interpolate(_list(0))])},
  "Please enter a valid email address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez saisir une adresse e-mail valide."])},
  "Please enter a valid password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez saisir un mot de passe valide."])},
  "Please enter a valid email address and password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez saisir une adresse e-mail et un mot de passe valide."])},
  "Forgot Password?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe oublié ?"])},
  "Please enter the code received by email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez saisir le code reçu sur votre adresse mail"])},
  "Verify code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valider le code"])},
  "Please enter a valid code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre code saisi est incorrect. Veuillez saisir le code reçu sur votre adresse mail."])},
  "The string should have a minimum length of n characters": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["La chaîne doit avoir une longueur minimale de ", _interpolate(_list(0)), " caractère", _interpolate(_list(1))])},
  "The string should have a maximum length of n characters": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["La chaîne doit avoir une longueur maximale de ", _interpolate(_list(0)), " caractères", _interpolate(_list(1))])},
  "The string should have a minimum of n uppercase letters": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["La chaîne doit comporter au moins ", _interpolate(_list(0)), " lettre majuscule", _interpolate(_list(1))])},
  "The string should have a minimum of n lowercase letters": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["La chaîne doit comporter au moins ", _interpolate(_list(0)), " lettre minuscule", _interpolate(_list(1))])},
  "The string should have a minimum of n digits": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["La chaîne doit comporter au moins ", _interpolate(_list(0)), " chiffre", _interpolate(_list(1))])},
  "The string should have a minimum of n symbols": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["La chaîne doit comporter au moins ", _interpolate(_list(0)), " symbole", _interpolate(_list(1))])},
  "Dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tableau de bord"])},
  "Licenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Licences"])},
  "Customers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clients"])},
  "Contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacts"])},
  "Products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produits"])},
  "Product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produit"])},
  "Users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateurs"])},
  "Groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupes"])},
  "Profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon compte"])},
  "Disconnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déconnexion"])},
  "Administration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration"])},
  "datetime-picker-select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
  "datetime-picker-cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
  "No Available Data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune donnée disponible"])},
  "Show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher"])},
  "of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sur"])},
  "Close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer"])},
  "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
  "Save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])},
  "Error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur"])},
  "Yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui"])},
  "No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non"])},
  "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])},
  "Active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actif"])},
  "No results found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun résultat trouvé"])},
  "Please complete this field.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez renseigner ce champ."])},
  "The list is empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La liste est vide"])},
  "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
  "Register Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de création"])},
  "Last Activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernière modification"])},
  "Number of users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre d'utilisateurs"])},
  "Number of auth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre d'autorisation"])},
  "Rights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Droits"])},
  "Add new rights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter des droits"])},
  "Add new users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter des utilisateurs"])},
  "Firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
  "Lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lastname"])},
  "Language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langue"])},
  "Phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléphone"])},
  "Create group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un groupe"])},
  "Update group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour le groupe"])},
  "Delete group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer un groupe"])},
  "Confirm delete group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etes-vous sûr de vouloir supprimer ce groupe ?"])},
  "Create user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un utilisateur"])},
  "Update user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour l'utilisateur"])},
  "Passwords do not match.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les mots de passe ne correspondent pas"])},
  "Lock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérouiller"])},
  "Unlock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dévérouiller"])},
  "Lock user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérouiller un utilisateur"])},
  "Unlock user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dévérouiller un utilisateur"])},
  "Confirm lock user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etes-vous sûr de vouloir vérouiller cet utilisateur ?"])},
  "Confirm unlock user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etes-vous sûr de vouloir dévérouiller cet utilisateur ?"])},
  "Create product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un produit"])},
  "Update product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour un produit"])},
  "Delete product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer un produit"])},
  "Confirm delete product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etes-vous sûr de vouloir supprimer ce produit ?"])},
  "Create contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un contact"])},
  "Update contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour un contact"])},
  "Delete contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer un contact"])},
  "Confirm delete contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etes-vous sûr de vouloir supprimer ce contact ?"])},
  "Create customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un client"])},
  "Update customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour un client"])},
  "Delete customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer un client"])},
  "Confirm delete customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etes-vous sûr de vouloir supprimer ce client ?"])},
  "Number of sites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de sites"])},
  "Number of contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de contacts"])},
  "Add new contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un contact"])},
  "Sites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sites"])},
  "Add new site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un site"])},
  "Create customer site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un site client"])},
  "Update customer site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour un site client"])},
  "Delete customer site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer un site client"])},
  "Confirm delete customer site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etes-vous sûr de vouloir supprimer ce site client ?"])},
  "Create customer before": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez créer le client pour ajouter des sites"])},
  "Create license": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une licence"])},
  "Update license": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour une licence"])},
  "Delete license": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer une licence"])},
  "Confirm delete license": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etes-vous sûr de vouloir supprimer cette licence ?"])},
  "Customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client"])},
  "Customer site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Site client"])},
  "License key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clé de license"])},
  "Start date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date d'activation"])},
  "End date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de fin"])},
  "Numbers of limitations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de limitations"])},
  "Generate license key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Générer une clé de licence"])},
  "Confirm generate key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etes-vous sûr de vouloir generer une nouvelle clé de licence ?"])},
  "Limitations of kamishibai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limitations de Kamishibai"])},
  "Limitations of flashmeeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limitations de Flash Meeting"])},
  "Limitations of resolution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limitations de Resolution"])},
  "Numbers of tables": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de tableaux max. (0 = illimité) : "])},
  "Numbers of users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre d'utilisateurs max. (0 = illimité) : "])},
  "Numbers of resolutions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de résolution max. (0 = illimité) : "])}
}