<template>
  <div class="flex h-full flex-col">
    <img class="m-3 mx-auto h-14" src="./../../public/img/logo_small.png" />
    <router-link to="/dashboard" v-slot="{ isActive }">
      <MenuEntry :title="t( 'Dashboard' )" icon="ViewDashboardOutlineIcon" :active="isActive">
        <ViewDashboardOutlineIcon></ViewDashboardOutlineIcon>
      </MenuEntry>
    </router-link>
    <router-link to="/licenses" v-slot="{ isActive }" v-if="is_auth( [ API_RIGHTS.GET_LICENSES ] )">
      <MenuEntry :title="t( 'Licenses' )" icon="KeyOutlineIcon" :active="isActive">
        <KeyOutlineIcon></KeyOutlineIcon>
      </MenuEntry>
    </router-link>
    <router-link to="/customers" v-slot="{ isActive }" v-if="is_auth( [ API_RIGHTS.GET_CUSTOMERS, API_RIGHTS.GET_CUSTOMER_SITES ] )">
      <MenuEntry :title="t( 'Customers' )" icon="AccountTieOutlineIcon" :active="isActive">
        <AccountTieOutlineIcon></AccountTieOutlineIcon>
      </MenuEntry>
    </router-link>
    <router-link to="/contacts" v-slot="{ isActive } " v-if="is_auth( [ API_RIGHTS.GET_CONTACTS ] )">
      <MenuEntry :title="t( 'Contacts' )" icon="ContactsOutlineIcon" :active="isActive">
        <ContactsOutlineIcon></ContactsOutlineIcon>
      </MenuEntry>
    </router-link>
    <router-link to="/products" v-slot="{ isActive }" v-if="is_auth( [ API_RIGHTS.GET_PRODUCTS ] )">
      <MenuEntry :title="t( 'Products' )" icon="QRCodeIcon" :active="isActive">
        <QRCodeIcon></QRCodeIcon>
      </MenuEntry>
    </router-link>
    <!-- <MenuGroupEntry :title="t( 'Administration' )" icon="" v-if="is_auth( [ API_RIGHTS.GET_ACCOUNTS, API_RIGHTS.GET_GROUPS ] )"> -->
      <router-link to="/users" v-slot="{ isActive }" v-if="is_auth( [ API_RIGHTS.GET_ACCOUNTS ] )">
        <MenuEntry :title="t( 'Users' )" icon="AccountOutlineIcon" :active="isActive">
          <AccountOutlineIcon></AccountOutlineIcon>
        </MenuEntry>
      </router-link>
      <router-link to="/groups" v-slot="{ isActive }" v-if="is_auth( [ API_RIGHTS.GET_GROUPS ] )">
        <MenuEntry :title="t( 'Groups' )" icon="AccountGroupOutlineIcon" :active="isActive">
          <AccountGroupOutlineIcon></AccountGroupOutlineIcon>
        </MenuEntry>
      </router-link>
    <!-- </MenuGroupEntry> -->
    <p class="grow"></p>
    <router-link to="/profile" v-slot="{ isActive }">
      <MenuEntry :title="t( 'Profile' )" icon="AccountCircleOutlineIcon" :active="isActive">
        <AccountCircleOutlineIcon></AccountCircleOutlineIcon>
      </MenuEntry>
    </router-link>
    <a href="#" @click="logout()">
      <MenuEntry :title="t( 'Disconnect' )" icon="LogoutIcon" :active="false">
        <LogoutIcon></LogoutIcon>
      </MenuEntry>
    </a>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n';
import router from '@/router';
import store from '@/store';
import axios from '@/features/axios';
import utils from '@/features/utils';
// import MenuGroupEntry from '@/components/menu/MenuGroupEntry.vue';
import MenuEntry from '@/components/menu/MenuEntry.vue';
import API_RIGHTS from '@/constants/rights.constants';

import ViewDashboardOutlineIcon from 'vue-material-design-icons/ViewDashboardOutline.vue';
import KeyOutlineIcon from 'vue-material-design-icons/KeyOutline.vue';
import QRCodeIcon from 'vue-material-design-icons/Qrcode.vue';
import ContactsOutlineIcon from 'vue-material-design-icons/ContactsOutline.vue';
import AccountOutlineIcon from 'vue-material-design-icons/AccountOutline.vue';
import AccountTieOutlineIcon from 'vue-material-design-icons/AccountTieOutline.vue';
import AccountGroupOutlineIcon from 'vue-material-design-icons/AccountGroupOutline.vue';
import AccountCircleOutlineIcon from 'vue-material-design-icons/AccountCircleOutline.vue';
import LogoutIcon from 'vue-material-design-icons/Logout.vue';

export default {
  name: 'left-bar-view',
  components: {
    // MenuGroupEntry,
    MenuEntry,
    ViewDashboardOutlineIcon,
    KeyOutlineIcon,
    QRCodeIcon,
    ContactsOutlineIcon,
    AccountOutlineIcon,
    AccountTieOutlineIcon,
    AccountGroupOutlineIcon,
    AccountCircleOutlineIcon,
    LogoutIcon
  },
  setup() {
    const { t } = useI18n();

    const logout = () => {
      axios.post( 'api/auth/sign-out' )
        .then( () => {
          store.dispatch( 'setConnected', { connect: false, connect_user: null, token: '' } );
          router.push( '/login' );
        } )
        .catch( e => {
          utils.show_catch( e );
        } );
    };

    const is_auth = keys => {
      return utils.is_auth( keys );
    };

    return { t, API_RIGHTS, logout, is_auth };
  }
};
</script>

<style scoped lang="scss">
</style>