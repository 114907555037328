<template>
  <div class="flex min-h-full flex-row">
    <div class="b-2 bg-gray-800 text-white">
      <div class="h-full w-52">
        <MenuView></MenuView>
      </div>
    </div>
    <div class="w-full bg-white p-2 text-black" id="modals-container">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import MenuView from './MenuView.vue';

export default {
  name: 'main-view',
  components: {
    MenuView
  },
  setup() {
    return {};
  }
};
</script>
