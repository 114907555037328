<template>
  <StandardModal :title="title" :confirmText="t( 'Yes' )">
    <div class="p-4 text-center text-gray-500" >
      <span>{{message }}</span>
    </div>
  </StandardModal>
</template>

<script>
import { useI18n } from 'vue-i18n';
import StandardModal from './StandardModal.vue';

export default {
  name: 'add-users-modal-component',
  components: {
    StandardModal
  },
  props: {
    title: { String, required: true },
    message: { String, required: true }
  },
  setup() {
    const { t } = useI18n();

    return { t };
  }
};
</script>