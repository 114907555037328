<template>
  <input ref="elem_input" class="h-9 w-full border-b-2 border-blue-500 p-2 text-blue-800 outline-none focus:bg-gray-300"
    :type="type" :min="min" :max="max" autocomplete="off" :value="modelValue" :readonly="readonly"
    @input="$emit('update:modelValue', $event.target.value)">
</template>

<script>
import { ref } from 'vue';

export default {
  name: 'input-std-component',
  components: {
  },
  props: {
    type: { String, required: true, validator( value ) { return [ 'text', 'password', 'number' ].includes( value ); } },
    modelValue: { String, required: true },
    readonly: { Boolean, required: false, default: false },
    min: { Number, require: true },
    max: { Number, require: true }
  },
  setup() {
    const elem_input = ref( null );

    const focus = () => {
      elem_input.value.focus();
    };

    return { elem_input, focus };
  }
};
</script>