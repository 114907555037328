import { ref } from 'vue';
import i18n from '../i18n';
import passwordValidator from 'password-validator';

const { t } = i18n.global;
const min = ref( 12 );
const max = ref( 50 );
const uppercase = ref( 2 );
const lowercase = ref( 2 );
const digits = ref( 2 );
const symbols = ref( 2 );

const passwordSchema = new passwordValidator();

passwordSchema
  .is().min( min.value, t( 'The string should have a minimum length of n characters', [ min.value, min.value > 1 ? 's' : '' ] ) )
  .is().max( max.value, t( 'The string should have a maximum length of n characters', [ max.value, max.value > 1 ? 's' : '' ] ) )
  .has().uppercase( uppercase.value, t( 'The string should have a minimum of n uppercase letters', [ uppercase.value, uppercase.value > 1 ? 's' : '' ] ) )
  .has().lowercase( lowercase.value, t( 'The string should have a minimum of n lowercase letters', [ lowercase.value, lowercase.value > 1 ? 's' : '' ] ) )
  .has().digits( digits.value, t( 'The string should have a minimum of n digits', [ digits.value, digits.value > 1 ? 's' : '' ] ) )
  .has().symbols( symbols.value, t( 'The string should have a minimum of n symbols', [ symbols.value, symbols.value > 1 ? 's' : '' ] ) );

export default {
  validate( value ) {
    return passwordSchema.validate( value, { details: true } );
  }
};